// src/components/CppPythonExpertComponent.js

import React from 'react';
import SandpackComponent from './SandpackComponent';
import PromptGenerator from './PromptGenerator';

const CppPythonExpertComponent = (props) => {
  const {
    sendData,
    inputData,
    handleInputChange,
    handleKeyDown,
    loading,
    promptSent,
    sandpackFiles,
    modificationInput,
    setModificationInput,
    sendModification,
    usePromptGenerator,
    setUsePromptGenerator,
    useSearch,
    setUseSearch,
    attachFiles,
    setAttachFiles,
    model,
    setModel,
    handlePromptGenerated,
  } = props;

  return (
    <>
      {!promptSent && (
        <div className="content">
          <h1>
            Make Everything with your Personal{' '}
            <span className="highlight-blue">Python and C++ Expert Developer</span>
          </h1>
          {usePromptGenerator ? (
            <>
              <div className="prompt-generator-wrapper">
                <PromptGenerator onPromptGenerated={handlePromptGenerated} />
                <button className="switch-button" onClick={() => setUsePromptGenerator(false)}>
                  Escribe tu prompt
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="small-text">
                Generate C++ and Python code effortlessly.
              </h3>
              <div className="input-group">
                <div className="input-wrapper">
                  <textarea
                    className="auto-resize"
                    placeholder="Genera código en C++ o Python..."
                    value={inputData}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                  <button className="try-button" onClick={sendData}>
                    {/* SVG Icon */}
                  </button>
                </div>
              </div>

              <div className="options-group">
                <label className="option-checkbox">
                  <input
                    type="checkbox"
                    checked={useSearch}
                    onChange={() => setUseSearch(!useSearch)}
                  />
                  Search online?
                </label>
                <label className="option-checkbox">
                  <input
                    type="checkbox"
                    checked={attachFiles}
                    onChange={() => setAttachFiles(!attachFiles)}
                  />
                  Upload Files?
                </label>
              </div>

              <div className="model-selection">
                <label htmlFor="model-select">AI Technology:</label>
                <select
                  id="model-select"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value="shadcn-typescript">Shadcn/UI + Typescript</option>
                  <option value="javascript-sandboxer">Javascript Sandboxer</option>
                  <option value="cpp-python-expert">C++ and Python Expert Developer</option>
                </select>
              </div>

              <button className="switch-button" onClick={() => setUsePromptGenerator(true)}>
                Usar generador de prompts
              </button>
            </>
          )}
        </div>
      )}

      {promptSent && (
        <>
          <SandpackComponent sandpackFiles={sandpackFiles} dependencies={{}} />
          <div className="input-group">
            <div className="input-wrapper">
              <textarea
                className="auto-resize"
                placeholder="Describe las modificaciones que deseas..."
                value={modificationInput}
                onChange={(e) => setModificationInput(e.target.value)}
              />
              <button className="try-button" onClick={sendModification}>
                {/* SVG Icon */}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CppPythonExpertComponent;
