import React, { useState } from 'react';
import './PromptGenerator.css';

const PromptGenerator = ({ onPromptGenerated }) => {
  const [step, setStep] = useState(1);
  const [pageName, setPageName] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [hasLogo, setHasLogo] = useState(null); // Valor nulo inicialmente para que el usuario elija
  const [selectedPalette, setSelectedPalette] = useState('');
  const [cta, setCta] = useState('');
  const [sections, setSections] = useState([]);
  const [responsive, setResponsive] = useState('yes');
  const [navbarOptions, setNavbarOptions] = useState([]);

  const handleNextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSectionChange = (section) => {
    setSections(prev =>
      prev.includes(section)
        ? prev.filter(s => s !== section)
        : [...prev, section]
    );
  };

  const handlePaletteSelect = (palette) => {
    setSelectedPalette(palette);
  };

  // Función para validar y manejar el cambio de logo
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webm'];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (!validTypes.includes(file.type)) {
        alert('Solo se permiten archivos JPEG, JPG, PNG y WEBM.');
        return;
      }

      if (file.size > maxSize) {
        alert('El archivo es demasiado grande. Máximo 2 MB.');
        return;
      }

      setLogo(file);
    }
  };

  // Función para subir el logo al servidor
  const uploadLogo = async (file) => {
    const formData = new FormData();
    formData.append('logo', file);

    try {
      const response = await fetch('https://api.xtarify.com/api/upload-img/', {
        method: 'POST',
        body: formData,
        mode: 'cors', // Habilitar CORS para solicitudes cross-origin
      });

      if (response.ok) {
        const data = await response.json();
        return data.url; // La URL de la imagen guardada
      } else {
        alert('Error al subir el logo.');
        return null;
      }
    } catch (error) {
      console.error('Error al subir el logo:', error);
      alert('Error al subir el logo.');
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let logoUrl = '';

    if (hasLogo && logo) {
      // Subir el logo al servidor y obtener la URL
      logoUrl = await uploadLogo(logo);
      if (!logoUrl) {
        // Si hubo un error al subir el logo, no continuamos
        return;
      }
    }

    const prompt = `Crea una página web llamada "${pageName}" implementa un nav bar con las secciones que te pasare abajo con los siguientes detalles:\n` +
      `- Logo: ${hasLogo ? (logoUrl ? `Incluye logo en ${logoUrl}` : 'Sin logo') : 'Sin logo'}\n` +
      `- Paleta de colores USA GRADIENTES PARA EL FONDO: ${selectedPalette}IMPORTANT, DONT USE WHITE FRO THE LETTERS, use these colors as a predefined color palette. I want you to use them in the letters. background, buttons and whatever is on the page. order them as most convenient \n`  +  
      `- CTA, esta frase trata de atraer al usuario asi que mejoralo aumentale contexto de ese rubro: ${cta}\n` +
      `- Secciones, estas secciones tambien tienen que ser incluidas en el nav bar el nav bar debe ser responsive: ${sections.join(', ')}\n` +
      `- Diseño responsive, con buen diseño y el nav bar debe tener un nav toggle funcional: ${responsive}\n`;
      /// pequeña descripcion de tu idea de

    // Llama a la función pasada por props
    onPromptGenerated(prompt);
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="step">
            <div className="form-group">
              <label>¿Cómo se llama la página?</label>
              <input
                type="text"
                value={pageName}
                onChange={(e) => setPageName(e.target.value)}
                placeholder="Nombre de la empresa o proyecto"
                required
              />
            </div>

            <div className="form-group">
              <label>¿Tienes un logo que deseas subir?</label>
              <div className="checkbox-group">
                <label className="checkbox-custom">
                  <input
                    type="radio"
                    name="logo"
                    checked={hasLogo === true}
                    onChange={() => setHasLogo(true)}
                  />
                  Sí
                </label>
                <label className="checkbox-custom">
                  <input
                    type="radio"
                    name="logo"
                    checked={hasLogo === false}
                    onChange={() => setHasLogo(false)}
                  />
                  No
                </label>
              </div>
              {hasLogo && (
                <div className="form-group">
                  <input
                    type="file"
                    onChange={handleLogoChange}
                    accept=".jpeg,.jpg,.png,.webm"
                  />
                  {logo && (
                    <div className="image-preview">
                      <img src={URL.createObjectURL(logo)} alt="Logo preview" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        
{step === 2 && (
          <div className="step">
            <div className="form-group">
              <label>¿Qué colores prefieres?</label>
              <div className="color-palette">
                {[
                  { id: 'paleta1', classes: 'bg-blue-900 bg-blue-800 bg-blue-400 bg-gray-200' },
                  { id: 'paleta2', classes: 'bg-gray-200 bg-gray-900 bg-purple-600 bg-red-500' },
                  { id: 'paleta3', classes: 'bg-gray-100 bg-teal-400 bg-teal-600 bg-gray-700' },
                  { id: 'paleta4', classes: 'bg-yellow-100 bg-gray-500 bg-red-900 bg-gray-800' }
                ].map((palette, index) => (
                  <button
                    key={index}
                    type="button"
                    className={`palette-button ${palette.classes} ${selectedPalette === palette.id ? 'selected' : ''}`}
                    onClick={() => handlePaletteSelect(palette.id)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/${palette.id}.png`}
                      alt={`Palette ${index + 1}`}
                    />
                  </button>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>¿Cuál es el mensaje principal o CTA?</label>
              <input
                type="text"
                value={cta}
                onChange={(e) => setCta(e.target.value)}
                placeholder="Texto corto y conciso"
                required
              />
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="step">
            <div className="form-group">
              <label>¿Qué secciones deseas incluir?</label>
              <div className="checkbox-group">
                {['Hero', 'Sobre nosotros', 'Contacto', 'Testimonios', 'CTA'].map(section => (
                  <label key={section} className="checkbox-custom">
                    <input
                      type="checkbox"
                      checked={sections.includes(section)}
                      onChange={() => handleSectionChange(section)}
                    />
                    {section}
                  </label>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>Opciones para la barra de navegación:</label>
              <div className="checkbox-group">
                {['Inicio', 'Sobre Nosotros', 'Servicios', 'Contacto'].map(option => (
                  <label key={option} className="checkbox-custom">
                    <input
                      type="checkbox"
                      checked={navbarOptions.includes(option)}
                      onChange={() =>
                        setNavbarOptions(prev =>
                          prev.includes(option)
                            ? prev.filter(o => o !== option)
                            : [...prev, option]
                        )
                      }
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="navigation-buttons">
          {step > 1 && (
            <button type="button" onClick={handlePrevStep}>
              Atrás
            </button>
          )}
          {step < 3 && (
            <button type="button" onClick={handleNextStep}>
              Siguiente
            </button>
          )}
          {step === 3 && (
            <button type="submit">Generar Prompt</button>
          )}
        </div>
      </form>
    </div>
  );
};

export default PromptGenerator;