// src/components/JavascriptSandboxer.js

import React, { useState } from 'react';
import { Sandpack } from '@codesandbox/sandpack-react';
import { amethyst } from '@codesandbox/sandpack-themes';

const JavascriptSandboxer = () => {
  const [inputData2, setInputData2] = useState('');
  const [sandpackFiles2, setSandpackFiles2] = useState({
    '/index.html': {
      code: '<!-- Genera una página web... -->',
      hidden: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const [modificationInput2, setModificationInput2] = useState('');

  // Función para enviar datos al servidor
  const sendData2 = () => {
    if (!inputData2) {
      alert('Por favor ingresa un dato para generar.');
      return;
    }

    setLoading(true);

    // Limpiar el código del Sandpack y mostrar "Generando..."
    setSandpackFiles2({
      '/index.html': {
        code: '<!-- Generando... -->',
        hidden: false,
      },
    });

    // Simular un POST al servidor con un tiempo de espera
    setTimeout(() => {
      fetch('https://api.xtarify.com/api/js-box/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt2: inputData2,
        }),
        mode: 'cors',
      })
        .then((response2) => response2.json())
        .then((result2) => {
          let responseText2 = result2.response2 || '<!-- No se pudo obtener la respuesta de la IA -->';
          // Actualizar el código que se muestra en Sandpack con la respuesta de la IA
          setSandpackFiles2({
            '/index.html': {
              code: responseText2,
              hidden: false,
            },
          });
          setLoading(false); // Detener la animación cuando el servidor responde
        })
        .catch((error2) => {
          console.error('Error:', error2);
          alert('Hubo un error al enviar los datos');
          setLoading(false); // Detener la animación si hay un error
        });
    }, 1000); // Simula un retraso de 1 segundo en la respuesta
  };

  // Funciones para manejar cambios en el input
  const handleInputChange2 = (e) => {
    setInputData2(e.target.value);
  };

  const handleSendData2 = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendData2();
    }
  };

  // Función para enviar modificaciones
  const sendModification2 = () => {
    if (!modificationInput2) {
      alert('Por favor ingresa tus modificaciones.');
      return;
    }

    setLoading(true);

    // Enviar solicitud POST al backend
    fetch('https://api.xtarify.comapi/improve-box/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        modifications2: modificationInput2,
        code2: sandpackFiles2['/index.html'].code,
      }),
      mode: 'cors',
    })
      .then((response2) => response2.json())
      .then((result2) => {
        let responseText2 = result2.response2 || '<!-- No se pudo obtener la respuesta de la IA -->';
        // Actualizar el código que se muestra en Sandpack con el código mejorado
        setSandpackFiles2({
          '/index.html': {
            code: responseText2,
            hidden: false,
          },
        });
        setLoading(false);
      })
      .catch((error2) => {
        console.error('Error:', error2);
        alert('Hubo un error al enviar las modificaciones');
        setLoading(false);
      });
  };

  return (
    <div className="sandpack-container">
      <Sandpack
        theme={amethyst}
        template="static"
        files={sandpackFiles2}
        options={{
          showNavigator: true,
          showTabs: true,
          editorHeight: 700,
          resizablePanels: true,
          showLineNumbers: true,
          wrapContent: true,
        }}
        className="sp-wrapper"
      />
      {/* Input para modificaciones */}
      <div className="input-group2">
        <textarea
          className="auto-resize"
          placeholder="Describe las modificaciones que deseas..."
          value={modificationInput2}
          onChange={(e) => setModificationInput2(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              sendModification2();
            }
          }}
        />
        <button className="try-button" onClick={sendModification2}>
          →
        </button>
      </div>
      {/* Input para nueva generación */}
      <div className="input-group2">
        <textarea
          className="auto-resize"
          placeholder="Genera una página web..."
          value={inputData2}
          onChange={handleInputChange2}
          onKeyDown={handleSendData2}
        />
        <button className="try-button" onClick={sendData2}>
          →
        </button>
      </div>
    </div>
  );
};

export default JavascriptSandboxer;
