// src/components/ShadcnTypescript.js

import React, { useState } from 'react';
import { Sandpack } from '@codesandbox/sandpack-react';
import { nightOwl } from '@codesandbox/sandpack-themes';

const ShadcnTypescript = () => {
  const [inputData, setInputData] = useState('');
  const [sandpackFiles, setSandpackFiles] = useState({
    '/App.js': {
      code: '// Genera una aplicación React...',
    },
    // Puedes agregar otros archivos necesarios
  });
  const [loading, setLoading] = useState(false);
  const [modificationInput, setModificationInput] = useState('');

  // Función para enviar datos al servidor
  const sendData = () => {
    if (!inputData) {
      alert('Por favor ingresa un dato para generar.');
      return;
    }

    setLoading(true);

    // Limpiar el código del Sandpack y mostrar "Generando..."
    setSandpackFiles({
      '/App.js': {
        code: '// Generando...\n',
      },
    });

    // Simular un POST al servidor con un tiempo de espera
    setTimeout(() => {
      fetch('https://api.xtarify.com/api/chat/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: inputData,
        }),
        mode: 'cors',
      })
        .then((response) => response.json())
        .then((result) => {
          let responseText = result.response || '// No se pudo obtener la respuesta de la IA';
          // Actualizar el código que se muestra en Sandpack con la respuesta de la IA
          setSandpackFiles({
            '/App.js': {
              code: responseText,
            },
            // Asegúrate de agregar otros archivos necesarios aquí
          });
          setLoading(false); // Detener la animación cuando el servidor responde
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Hubo un error al enviar los datos');
          setLoading(false); // Detener la animación si hay un error
        });
    }, 1000); // Simula un retraso de 1 segundo en la respuesta
  };

  // Funciones para manejar cambios en el input
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleSendData = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendData();
    }
  };

  // Función para enviar modificaciones
  const sendModification = () => {
    if (!modificationInput) {
      alert('Por favor ingresa tus modificaciones.');
      return;
    }

    setLoading(true);

    // Enviar solicitud POST al backend
    fetch('https://api.xtarify.com/api/improve-code/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        modifications: modificationInput,
        code: sandpackFiles['/App.js'].code,
      }),
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((result) => {
        let responseText = result.response || '// No se pudo obtener la respuesta de la IA';
        // Actualizar el código que se muestra en Sandpack con el código mejorado
        setSandpackFiles({
          '/App.js': {
            code: responseText,
          },
          // Actualiza otros archivos si es necesario
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Hubo un error al enviar las modificaciones');
        setLoading(false);
      });
  };

  return (
    <div className="sandpack-container">
      <Sandpack
        theme={nightOwl}
        template="react"
        files={sandpackFiles}
        customSetup={{
          dependencies: {
            // Agrega aquí las dependencias necesarias para shadcn+typescript
            "react": "^17.0.0",
            "react-dom": "^17.0.0",
            // ...otras dependencias...
          },
        }}
        options={{
          showNavigator: false,
          showTabs: true,
          editorHeight: 700,
          resizablePanels: true,
          showLineNumbers: true,
          wrapContent: true,
        }}
        className="sp-wrapper"
      />
      {/* Input para modificaciones */}
      <div className="input-group2">
        <textarea
          className="auto-resize"
          placeholder="Describe las modificaciones que deseas..."
          value={modificationInput}
          onChange={(e) => setModificationInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              sendModification();
            }
          }}
        />
        <button className="try-button" onClick={sendModification}>
          →
        </button>
      </div>
      {/* Input para nueva generación */}
      <div className="input-group2">
        <textarea
          className="auto-resize"
          placeholder="Genera una aplicación React..."
          value={inputData}
          onChange={handleInputChange}
          onKeyDown={handleSendData}
        />
        <button className="try-button" onClick={sendData}>
          →
        </button>
      </div>
    </div>
  );
};

export default ShadcnTypescript;
