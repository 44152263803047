// src/components/SandpackComponent.js

import React from 'react';
import { Sandpack } from '@codesandbox/sandpack-react';
import { nightOwl } from '@codesandbox/sandpack-themes';
import PropTypes from 'prop-types';

const SandpackComponent = ({ sandpackFiles }) => {
  return (
    <div className="sandpack-container">
      <Sandpack
        theme={nightOwl}
        template="react"
        customSetup={{
          dependencies: {
            "react": "^17.0.0",
            "react-dom": "^17.0.0",
            "react-scripts": "^4.0.0",
            "lucide-react": "latest",
            "recharts": "2.9.0",
            "react-router-dom": "latest",
            "@radix-ui/react-accordion": "^1.2.0",
            "@radix-ui/react-alert-dialog": "^1.1.1",
            "@radix-ui/react-aspect-ratio": "^1.1.0",
            "@radix-ui/react-avatar": "^1.1.0",
            "@radix-ui/react-checkbox": "^1.1.1",
            "@radix-ui/react-collapsible": "^1.1.0",
            "@radix-ui/react-dialog": "^1.1.1",
            "@radix-ui/react-dropdown-menu": "^2.1.1",
            "@radix-ui/react-hover-card": "^1.1.1",
            "@radix-ui/react-label": "^2.1.0",
            "@radix-ui/react-menubar": "^1.1.1",
            "@radix-ui/react-navigation-menu": "^1.2.0",
            "@radix-ui/react-popover": "^1.1.1",
            "@radix-ui/react-progress": "^1.1.0",
            "@radix-ui/react-radio-group": "^1.2.0",
            "@radix-ui/react-select": "^2.1.1",
            "@radix-ui/react-separator": "^1.1.0",
            "@radix-ui/react-slider": "^1.2.0",
            "@radix-ui/react-slot": "^1.1.0",
            "@radix-ui/react-switch": "^1.1.0",
            "@radix-ui/react-tabs": "^1.1.0",
            "@radix-ui/react-toast": "^1.2.1",
            "@radix-ui/react-toggle": "^1.1.0",
            "@radix-ui/react-toggle-group": "^1.1.0",
            "@radix-ui/react-tooltip": "^1.1.2",
            "class-variance-authority": "^0.5.1",
            "clsx": "^1.2.1", // Versión corregida
            "date-fns": "^2.29.3",
            "embla-carousel-react": "^7.0.0",
            "react-day-picker": "^8.7.1",
            "tailwind-merge": "^1.14.0", // Versión corregida
            "tailwindcss-animate": "^1.0.3",
            "vaul": "^0.7.1"
          },
          entry: '/index.js',
        }}
        files={sandpackFiles}
        options={{
          externalResources: [
            "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css",
          ],
          showNavigator: false,
          showTabs: false,
          editorHeight: 800,
          resizablePanels: true,
          showLineNumbers: true,
          wrapContent: false,
          editorWidthPercentage: 25, // default - 50
        }}
        className="sp-wrapper"
      />
    </div>
  );
};

SandpackComponent.propTypes = {
  sandpackFiles: PropTypes.object.isRequired,
};

export default SandpackComponent;
