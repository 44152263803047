import React, { useState, useEffect } from 'react';
import './App.css';
import PromptGenerator from './components/PromptGenerator'; // Si utilizas este componente
import SandpackComponent from './components/SandpackComponent'; // Si utilizas este componente
import JavascriptSandboxer from './components/JavascriptSandboxer'; // Importamos el componente JavascriptSandboxer
import ShadcnTypescript from './components/ShadcnTypescript'; // Importamos el componente ShadcnTypescript
import CppPythonExpert from './components/CppPythonExpert'; // Si tienes este componente

function App() {
  const [versionVisible, setVersionVisible] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [inputData, setInputData] = useState('');
  const [useSearch, setUseSearch] = useState(false);
  const [attachFiles, setAttachFiles] = useState(false);
  const [model, setModel] = useState('shadcn-typescript');
  const [sandpackFiles, setSandpackFiles] = useState({
    'tailwind.css': {
      code: `
        @tailwind base;
        @tailwind components;
        @tailwind utilities;
      `,
    },
  });

  const [promptSent, setPromptSent] = useState(false);
  const [loading, setLoading] = useState(false); // Para manejar la animación de carga
  const [modificationInput, setModificationInput] = useState('');
  const texts = [
    "Procesando tu instrucción",
    "Escogiendo la tecnología",
    "Generando un diseño único",
    "Formulando Algoritmos",
    "Programando",
    "Solucionando bugs",
    "Tu web está casi lista",
  ];

  const [currentText, setCurrentText] = useState(texts[0]);
  const [textIndex, setTextIndex] = useState(0);

  const [usePromptGenerator, setUsePromptGenerator] = useState(true); // Nuevo estado

  // Lógica del Beta Version Toggle
  const toggleVersionInfo = () => {
    setVersionVisible(!versionVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const versionInfo = document.getElementById('versionInfo');
      const betaButton = document.getElementById('betaButton');
      if (
        versionInfo &&
        betaButton &&
        !versionInfo.contains(event.target) &&
        !betaButton.contains(event.target)
      ) {
        setVersionVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Función para manejar la selección del modelo
  const handleModelSelect = (e) => {
    setModel(e.target.value);
  };

  // Lógica del Feedback Modal
  const openFeedback = () => {
    setFeedbackVisible(true);
  };

  const closeFeedback = () => {
    setFeedbackVisible(false);
  };

  const handleFeedbackClickOutside = (event) => {
    if (event.target.className === 'feedback-modal') {
      closeFeedback();
    }
  };

  const handleFeedbackSubmit = () => {
    // Verifica que el feedback no esté vacío
    if (!feedbackText.trim()) {
      alert('El campo de feedback está vacío');
      return;
    }
    // Sanitizar el feedback en el frontend antes de enviarlo
    const sanitizedFeedback = feedbackText.replace(/[<>]/g, ''); // Eliminar símbolos peligrosos

    // Enviar el feedback al backend
    fetch('https://api.xtarify.com/api/save-feedback/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ comments: sanitizedFeedback }),
      mode: 'cors', // Habilitar CORS para solicitudes cross-origin

    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          alert('Feedback enviado con éxito');
          setFeedbackText(''); // Limpia el campo de feedback después de enviarlo
          closeFeedback(); // Cierra el modal
        } else {
          alert('Error al enviar el feedback');
        }
      })
      .catch((error) => {
        console.error('Error al enviar el feedback:', error);
      });
  };

  // Lógica del Textarea Auto-resize
  useEffect(() => {
    const textarea = document.querySelector('.auto-resize');
    if (textarea) {
      textarea.addEventListener('input', function () {
        this.style.height = 'auto';
        this.style.height = this.scrollHeight + 'px';
      });
    }
  }, []);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 4000); // Cambia de frase cada 4 segundos

      return () => clearInterval(intervalId); // Limpia el intervalo al desmontar
    }
  }, [loading, texts.length]);

  useEffect(() => {
    setCurrentText(texts[textIndex]);
  }, [textIndex, texts]);

  // Función para manejar el envío de datos
  const sendData = () => {
    if (!inputData) {
      alert('Por favor ingresa un dato para generar.');
      return;
    }

    // Mostrar la animación de carga
    setLoading(true);

    // Limpiar el código del Sandpack y mostrar "Generando..."
    setSandpackFiles({
      'App.js': {
        code: '// Generando...\n',
        hidden: false,
      },
    });

    // Simular un POST al servidor con un tiempo de espera
    setTimeout(() => {
      // Aquí iría la lógica de envío del POST real al servidor
      fetch('https://api.xtarify.com/api/chat/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          prompt: inputData,
          use_search: useSearch,
          attach_files: attachFiles,
          model: model,
        }),
        mode: 'cors',
      })
        .then((response) => response.json())
        .then((result) => {
          let responseText = result.response || '// No se pudo obtener la respuesta de la IA';
          ///////////////////////////////////////////
          // CODIGO PARA CARGAR DESPUES
           // Incluir componentes de shadcn/ui y configuración de Tailwind CSS 
           const shadcnComponents = {
            '/components/ui/button.tsx': {
              code: `
              import * as React from "react"
              import { Slot } from "@radix-ui/react-slot"
              import { cva, type VariantProps } from "class-variance-authority"

              import { cn } from '../../lib/utils';

              const buttonVariants = cva(
                "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
                {
                  variants: {
                    variant: {
                      default: "bg-gray-900 text-gray-50 hover:bg-gray-900/90 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90",
                      destructive:
                        "bg-red-500 text-gray-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/90",
                      outline:
                        "border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50",
                      secondary:
                        "bg-gray-100 text-gray-900 hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
                      ghost: "hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50",
                      link: "text-gray-900 underline-offset-4 hover:underline dark:text-gray-50",
                    },
                    size: {
                      default: "h-10 px-4 py-2",
                      sm: "h-9 rounded-md px-3",
                      lg: "h-11 rounded-md px-8",
                      icon: "h-10 w-10",
                    },
                  },
                  defaultVariants: {
                    variant: "default",
                    size: "default",
                  },
                }
              )

              export interface ButtonProps
                extends React.ButtonHTMLAttributes<HTMLButtonElement>,
                  VariantProps<typeof buttonVariants> {
                asChild?: boolean
              }

              const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
                ({ className, variant, size, asChild = false, ...props }, ref) => {
                  const Comp = asChild ? Slot : "button"
                  return (
                    <Comp
                      className={cn(buttonVariants({ variant, size, className }))}
                      ref={ref}
                      {...props}
                    />
                  )
                }
              )
              Button.displayName = "Button"

              export { Button, buttonVariants }
              
              `,hidden: false,
          },
          'components/ui/alert-dialog.tsx': {
              code: `
              import * as React from "react"
              import * as DialogPrimitive from "@radix-ui/react-dialog"
              import { X } from "lucide-react"

              import { cn } from "../../lib/utils"

              const Dialog = DialogPrimitive.Root

              const DialogTrigger = DialogPrimitive.Trigger

              const DialogPortal = DialogPrimitive.Portal

              const DialogClose = DialogPrimitive.Close

              const DialogOverlay = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Overlay>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
              >(({ className, ...props }, ref) => (
                <DialogPrimitive.Overlay
                  ref={ref}
                  className={cn(
                    "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                    className
                  )}
                  {...props}
                />
              ))
              DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

              const DialogContent = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Content>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
              >(({ className, children, ...props }, ref) => (
                <DialogPortal>
                  <DialogOverlay />
                  <DialogPrimitive.Content
                    ref={ref}
                    className={cn(
                      "fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-gray-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-gray-800 dark:bg-gray-950",
                      className
                    )}
                    {...props}
                  >
                    {children}
                    <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-100 data-[state=open]:text-gray-500 dark:ring-offset-gray-950 dark:focus:ring-gray-300 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-400">
                      <X className="h-4 w-4" />
                      <span className="sr-only">Close</span>
                    </DialogPrimitive.Close>
                  </DialogPrimitive.Content>
                </DialogPortal>
              ))
              DialogContent.displayName = DialogPrimitive.Content.displayName

              const DialogHeader = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLDivElement>) => (
                <div
                  className={cn(
                    "flex flex-col space-y-1.5 text-center sm:text-left",
                    className
                  )}
                  {...props}
                />
              )
              DialogHeader.displayName = "DialogHeader"

              const DialogFooter = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLDivElement>) => (
                <div
                  className={cn(
                    "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
                    className
                  )}
                  {...props}
                />
              )
              DialogFooter.displayName = "DialogFooter"

              const DialogTitle = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Title>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
              >(({ className, ...props }, ref) => (
                <DialogPrimitive.Title
                  ref={ref}
                  className={cn(
                    "text-lg font-semibold leading-none tracking-tight",
                    className
                  )}
                  {...props}
                />
              ))
              DialogTitle.displayName = DialogPrimitive.Title.displayName

              const DialogDescription = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Description>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
              >(({ className, ...props }, ref) => (
                <DialogPrimitive.Description
                  ref={ref}
                  className={cn("text-sm text-gray-500 dark:text-gray-400", className)}
                  {...props}
                />
              ))
              DialogDescription.displayName = DialogPrimitive.Description.displayName

              export {
                Dialog,
                DialogPortal,
                DialogOverlay,
                DialogClose,
                DialogTrigger,
                DialogContent,
                DialogHeader,
                DialogFooter,
                DialogTitle,
                DialogDescription,
              }


              
              `,
            },

          'components/ui/accordion.tsx': {
            code: `
            import * as React from "react"
            import * as AccordionPrimitive from "@radix-ui/react-accordion"
            import { ChevronDown } from "lucide-react"

            import { cn } from "../../lib/utils"

            const Accordion = AccordionPrimitive.Root

            const AccordionItem = React.forwardRef<
              React.ElementRef<typeof AccordionPrimitive.Item>,
              React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
            >(({ className, ...props }, ref) => (
              <AccordionPrimitive.Item
                ref={ref}
                className={cn("border-b", className)}
                {...props}
              />
            ))
            AccordionItem.displayName = "AccordionItem"

            const AccordionTrigger = React.forwardRef<
              React.ElementRef<typeof AccordionPrimitive.Trigger>,
              React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
            >(({ className, children, ...props }, ref) => (
              <AccordionPrimitive.Header className="flex">
                <AccordionPrimitive.Trigger
                  ref={ref}
                  className={cn(
                    "flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
                    className
                  )}
                  {...props}
                >
                  {children}
                  <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
                </AccordionPrimitive.Trigger>
              </AccordionPrimitive.Header>
            ))
            AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

            const AccordionContent = React.forwardRef<
              React.ElementRef<typeof AccordionPrimitive.Content>,
              React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
            >(({ className, children, ...props }, ref) => (
              <AccordionPrimitive.Content
                ref={ref}
                className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
                {...props}
              >
                <div className={cn("pb-4 pt-0", className)}>{children}</div>
              </AccordionPrimitive.Content>
            ))

            AccordionContent.displayName = AccordionPrimitive.Content.displayName

            export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }

            `,
          },
          'components/ui/alert.tsx': {
            code: `
            import * as React from "react"
            import { cva, type VariantProps } from "class-variance-authority"

            import { cn } from "../../lib/utils"

            const alertVariants = cva(
              "relative w-full rounded-lg border border-gray-200 p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-gray-950 dark:border-gray-800 dark:[&>svg]:text-gray-50",
              {
                variants: {
                  variant: {
                    default: "bg-white text-gray-950 dark:bg-gray-950 dark:text-gray-50",
                    destructive:
                      "border-red-500/50 text-red-500 dark:border-red-500 [&>svg]:text-red-500 dark:border-red-900/50 dark:text-red-900 dark:dark:border-red-900 dark:[&>svg]:text-red-900",
                  },
                },
                defaultVariants: {
                  variant: "default",
                },
              }
            )

            const Alert = React.forwardRef<
              HTMLDivElement,
              React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
            >(({ className, variant, ...props }, ref) => (
              <div
                ref={ref}
                role="alert"
                className={cn(alertVariants({ variant }), className)}
                {...props}
              />
            ))
            Alert.displayName = "Alert"

            const AlertTitle = React.forwardRef<
              HTMLParagraphElement,
              React.HTMLAttributes<HTMLHeadingElement>
            >(({ className, ...props }, ref) => (
              <h5
                ref={ref}
                className={cn("mb-1 font-medium leading-none tracking-tight", className)}
                {...props}
              />
            ))
            AlertTitle.displayName = "AlertTitle"

            const AlertDescription = React.forwardRef<
              HTMLParagraphElement,
              React.HTMLAttributes<HTMLParagraphElement>
            >(({ className, ...props }, ref) => (
              <div
                ref={ref}
                className={cn("text-sm [&_p]:leading-relaxed", className)}
                {...props}
              />
            ))
            AlertDescription.displayName = "AlertDescription"

            export { Alert, AlertTitle, AlertDescription }


            `,
          },
          'components/ui/avatar.tsx': {
            code: `
            import * as React from "react"
            import * as AvatarPrimitive from "@radix-ui/react-avatar"

            import { cn } from "../../lib/utils"

            const Avatar = React.forwardRef<
              React.ElementRef<typeof AvatarPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
            >(({ className, ...props }, ref) => (
              <AvatarPrimitive.Root
                ref={ref}
                className={cn(
                  "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
                  className
                )}
                {...props}
              />
            ))
            Avatar.displayName = AvatarPrimitive.Root.displayName

            const AvatarImage = React.forwardRef<
              React.ElementRef<typeof AvatarPrimitive.Image>,
              React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
            >(({ className, ...props }, ref) => (
              <AvatarPrimitive.Image
                ref={ref}
                className={cn("aspect-square h-full w-full", className)}
                {...props}
              />
            ))
            AvatarImage.displayName = AvatarPrimitive.Image.displayName

            const AvatarFallback = React.forwardRef<
              React.ElementRef<typeof AvatarPrimitive.Fallback>,
              React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
            >(({ className, ...props }, ref) => (
              <AvatarPrimitive.Fallback
                ref={ref}
                className={cn(
                  "flex h-full w-full items-center justify-center rounded-full bg-gray-100 dark:bg-gray-800",
                  className
                )}
                {...props}
              />
            ))
            AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

            export { Avatar, AvatarImage, AvatarFallback }


            `,
          },
            'components/ui/badge.tsx': {
              code: `
              import * as React from "react"
              import { cva, type VariantProps } from "class-variance-authority"

              import { cn } from "../../lib/utils"

              const badgeVariants = cva(
                "inline-flex items-center rounded-full border border-gray-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 dark:border-gray-800 dark:focus:ring-gray-300",
                {
                  variants: {
                    variant: {
                      default:
                        "border-transparent bg-gray-900 text-gray-50 hover:bg-gray-900/80 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/80",
                      secondary:
                        "border-transparent bg-gray-100 text-gray-900 hover:bg-gray-100/80 dark:bg-gray-800 dark:text-gray-50 dark:hover:bg-gray-800/80",
                      destructive:
                        "border-transparent bg-red-500 text-gray-50 hover:bg-red-500/80 dark:bg-red-900 dark:text-gray-50 dark:hover:bg-red-900/80",
                      outline: "text-gray-950 dark:text-gray-50",
                    },
                  },
                  defaultVariants: {
                    variant: "default",
                  },
                }
              )

              export interface BadgeProps
                extends React.HTMLAttributes<HTMLDivElement>,
                  VariantProps<typeof badgeVariants> {}

              function Badge({ className, variant, ...props }: BadgeProps) {
                return (
                  <div className={cn(badgeVariants({ variant }), className)} {...props} />
                )
              }

              export { Badge, badgeVariants }


              `,
            },
            'components/ui/calendar.tsx': {
              code: `
              import * as React from "react"
              import { ChevronLeft, ChevronRight } from "lucide-react"
              import { DayPicker } from "react-day-picker"

              import { cn } from "../../lib/utils"
              import { buttonVariants } from "./button"

              export type CalendarProps = React.ComponentProps<typeof DayPicker>

              function Calendar({
                className,
                classNames,
                showOutsideDays = true,
                ...props
              }: CalendarProps) {
                return (
                  <DayPicker
                    showOutsideDays={showOutsideDays}
                    className={cn("p-3", className)}
                    classNames={{
                      months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                      month: "space-y-4",
                      caption: "flex justify-center pt-1 relative items-center",
                      caption_label: "text-sm font-medium",
                      nav: "space-x-1 flex items-center",
                      nav_button: cn(
                        buttonVariants({ variant: "outline" }),
                        "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
                      ),
                      nav_button_previous: "absolute left-1",
                      nav_button_next: "absolute right-1",
                      table: "w-full border-collapse space-y-1",
                      head_row: "flex",
                      head_cell:
                        "text-gray-500 rounded-md w-9 font-normal text-[0.8rem] dark:text-gray-400",
                      row: "flex w-full mt-2",
                      cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-100/50 [&:has([aria-selected])]:bg-gray-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 dark:[&:has([aria-selected].day-outside)]:bg-gray-800/50 dark:[&:has([aria-selected])]:bg-gray-800",
                      day: cn(
                        buttonVariants({ variant: "ghost" }),
                        "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
                      ),
                      day_range_end: "day-range-end",
                      day_selected:
                        "bg-gray-900 text-gray-50 hover:bg-gray-900 hover:text-gray-50 focus:bg-gray-900 focus:text-gray-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50 dark:hover:text-gray-900 dark:focus:bg-gray-50 dark:focus:text-gray-900",
                      day_today: "bg-gray-100 text-gray-900 dark:bg-gray-800 dark:text-gray-50",
                      day_outside:
                        "day-outside text-gray-500 opacity-50 aria-selected:bg-gray-100/50 aria-selected:text-gray-500 aria-selected:opacity-30 dark:text-gray-400 dark:aria-selected:bg-gray-800/50 dark:aria-selected:text-gray-400",
                      day_disabled: "text-gray-500 opacity-50 dark:text-gray-400",
                      day_range_middle:
                        "aria-selected:bg-gray-100 aria-selected:text-gray-900 dark:aria-selected:bg-gray-800 dark:aria-selected:text-gray-50",
                      day_hidden: "invisible",
                      ...classNames,
                    }}
                    components={{
                      IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
                      IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
                    }}
                    {...props}
                  />
                )
              }
              Calendar.displayName = "Calendar"

              export { Calendar }


              `,
            },
            'components/ui/card.tsx': {
              code: `
              import * as React from 'react';

              import { cn } from '../../lib/utils';

              const Card = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement>
              >(({ className, ...props }, ref) => (
                <div
                  ref={ref}
                  className={cn(
                    "rounded-lg border border-gray-200 bg-white text-gray-950 shadow-sm dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                    className
                  )}
                  {...props}
                />
              ))
              Card.displayName = "Card"

              const CardHeader = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement>
              >(({ className, ...props }, ref) => (
                <div
                  ref={ref}
                  className={cn("flex flex-col space-y-1.5 p-6", className)}
                  {...props}
                />
              ))
              CardHeader.displayName = "CardHeader"

              const CardTitle = React.forwardRef<
                HTMLParagraphElement,
                React.HTMLAttributes<HTMLHeadingElement>
              >(({ className, ...props }, ref) => (
                <h3
                  ref={ref}
                  className={cn(
                    "text-2xl font-semibold leading-none tracking-tight",
                    className
                  )}
                  {...props}
                />
              ))
              CardTitle.displayName = "CardTitle"

              const CardDescription = React.forwardRef<
                HTMLParagraphElement,
                React.HTMLAttributes<HTMLParagraphElement>
              >(({ className, ...props }, ref) => (
                <p
                  ref={ref}
                  className={cn("text-sm text-gray-500 dark:text-gray-400", className)}
                  {...props}
                />
              ))
              CardDescription.displayName = "CardDescription"

              const CardContent = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement>
              >(({ className, ...props }, ref) => (
                <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
              ))
              CardContent.displayName = "CardContent"

              const CardFooter = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement>
              >(({ className, ...props }, ref) => (
                <div
                  ref={ref}
                  className={cn("flex items-center p-6 pt-0", className)}
                  {...props}
                />
              ))
              CardFooter.displayName = "CardFooter"

              export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }

              `,
            },
            '/components/ui/carousel.tsx': {
              code: `
              import * as React from "react"
              import useEmblaCarousel, {
                type UseEmblaCarouselType,
              } from "embla-carousel-react"
              import { ArrowLeft, ArrowRight } from "lucide-react"

              import { cn } from "../../lib/utils"
              import { Button } from "./button"

              type CarouselApi = UseEmblaCarouselType[1]
              type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
              type CarouselOptions = UseCarouselParameters[0]
              type CarouselPlugin = UseCarouselParameters[1]

              type CarouselProps = {
                opts?: CarouselOptions
                plugins?: CarouselPlugin
                orientation?: "horizontal" | "vertical"
                setApi?: (api: CarouselApi) => void
              }

              type CarouselContextProps = {
                carouselRef: ReturnType<typeof useEmblaCarousel>[0]
                api: ReturnType<typeof useEmblaCarousel>[1]
                scrollPrev: () => void
                scrollNext: () => void
                canScrollPrev: boolean
                canScrollNext: boolean
              } & CarouselProps

              const CarouselContext = React.createContext<CarouselContextProps | null>(null)

              function useCarousel() {
                const context = React.useContext(CarouselContext)

                if (!context) {
                  throw new Error("useCarousel must be used within a <Carousel />")
                }

                return context
              }

              const Carousel = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement> & CarouselProps
              >(
                (
                  {
                    orientation = "horizontal",
                    opts,
                    setApi,
                    plugins,
                    className,
                    children,
                    ...props
                  },
                  ref
                ) => {
                  const [carouselRef, api] = useEmblaCarousel(
                    {
                      ...opts,
                      axis: orientation === "horizontal" ? "x" : "y",
                    },
                    plugins
                  )
                  const [canScrollPrev, setCanScrollPrev] = React.useState(false)
                  const [canScrollNext, setCanScrollNext] = React.useState(false)

                  const onSelect = React.useCallback((api: CarouselApi) => {
                    if (!api) {
                      return
                    }

                    setCanScrollPrev(api.canScrollPrev())
                    setCanScrollNext(api.canScrollNext())
                  }, [])

                  const scrollPrev = React.useCallback(() => {
                    api?.scrollPrev()
                  }, [api])

                  const scrollNext = React.useCallback(() => {
                    api?.scrollNext()
                  }, [api])

                  const handleKeyDown = React.useCallback(
                    (event: React.KeyboardEvent<HTMLDivElement>) => {
                      if (event.key === "ArrowLeft") {
                        event.preventDefault()
                        scrollPrev()
                      } else if (event.key === "ArrowRight") {
                        event.preventDefault()
                        scrollNext()
                      }
                    },
                    [scrollPrev, scrollNext]
                  )

                  React.useEffect(() => {
                    if (!api || !setApi) {
                      return
                    }

                    setApi(api)
                  }, [api, setApi])

                  React.useEffect(() => {
                    if (!api) {
                      return
                    }

                    onSelect(api)
                    api.on("reInit", onSelect)
                    api.on("select", onSelect)

                    return () => {
                      api?.off("select", onSelect)
                    }
                  }, [api, onSelect])

                  return (
                    <CarouselContext.Provider
                      value={{
                        carouselRef,
                        api: api,
                        opts,
                        orientation:
                          orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
                        scrollPrev,
                        scrollNext,
                        canScrollPrev,
                        canScrollNext,
                      }}
                    >
                      <div
                        ref={ref}
                        onKeyDownCapture={handleKeyDown}
                        className={cn("relative", className)}
                        role="region"
                        aria-roledescription="carousel"
                        {...props}
                      >
                        {children}
                      </div>
                    </CarouselContext.Provider>
                  )
                }
              )
              Carousel.displayName = "Carousel"

              const CarouselContent = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement>
              >(({ className, ...props }, ref) => {
                const { carouselRef, orientation } = useCarousel()

                return (
                  <div ref={carouselRef} className="overflow-hidden">
                    <div
                      ref={ref}
                      className={cn(
                        "flex",
                        orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col",
                        className
                      )}
                      {...props}
                    />
                  </div>
                )
              })
              CarouselContent.displayName = "CarouselContent"

              const CarouselItem = React.forwardRef<
                HTMLDivElement,
                React.HTMLAttributes<HTMLDivElement>
              >(({ className, ...props }, ref) => {
                const { orientation } = useCarousel()

                return (
                  <div
                    ref={ref}
                    role="group"
                    aria-roledescription="slide"
                    className={cn(
                      "min-w-0 shrink-0 grow-0 basis-full",
                      orientation === "horizontal" ? "pl-4" : "pt-4",
                      className
                    )}
                    {...props}
                  />
                )
              })
              CarouselItem.displayName = "CarouselItem"

              const CarouselPrevious = React.forwardRef<
                HTMLButtonElement,
                React.ComponentProps<typeof Button>
              >(({ className, variant = "outline", size = "icon", ...props }, ref) => {
                const { orientation, scrollPrev, canScrollPrev } = useCarousel()

                return (
                  <Button
                    ref={ref}
                    variant={variant}
                    size={size}
                    className={cn(
                      "absolute  h-8 w-8 rounded-full",
                      orientation === "horizontal"
                        ? "-left-12 top-1/2 -translate-y-1/2"
                        : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
                      className
                    )}
                    disabled={!canScrollPrev}
                    onClick={scrollPrev}
                    {...props}
                  >
                    <ArrowLeft className="h-4 w-4" />
                    <span className="sr-only">Previous slide</span>
                  </Button>
                )
              })
              CarouselPrevious.displayName = "CarouselPrevious"

              const CarouselNext = React.forwardRef<
                HTMLButtonElement,
                React.ComponentProps<typeof Button>
              >(({ className, variant = "outline", size = "icon", ...props }, ref) => {
                const { orientation, scrollNext, canScrollNext } = useCarousel()

                return (
                  <Button
                    ref={ref}
                    variant={variant}
                    size={size}
                    className={cn(
                      "absolute h-8 w-8 rounded-full",
                      orientation === "horizontal"
                        ? "-right-12 top-1/2 -translate-y-1/2"
                        : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
                      className
                    )}
                    disabled={!canScrollNext}
                    onClick={scrollNext}
                    {...props}
                  >
                    <ArrowRight className="h-4 w-4" />
                    <span className="sr-only">Next slide</span>
                  </Button>
                )
              })
              CarouselNext.displayName = "CarouselNext"

              export {
                type CarouselApi,
                Carousel,
                CarouselContent,
                CarouselItem,
                CarouselPrevious,
                CarouselNext,
              }


              `,
            },
            '/components/ui/checkbox.tsx': {
              code: `
              import * as React from "react"
              import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
              import { Check } from "lucide-react"

              import { cn } from "../../lib/utils"

              const Checkbox = React.forwardRef<
                React.ElementRef<typeof CheckboxPrimitive.Root>,
                React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
              >(({ className, ...props }, ref) => (
                <CheckboxPrimitive.Root
                  ref={ref}
                  className={cn(
                    "peer h-4 w-4 shrink-0 rounded-sm border border-gray-200 dark:border-gray-900 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-gray-900 data-[state=checked]:text-gray-50  dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300 dark:data-[state=checked]:bg-gray-50 dark:data-[state=checked]:text-gray-900",
                    className
                  )}
                  {...props}
                >
                  <CheckboxPrimitive.Indicator
                    className={cn("flex items-center justify-center text-current")}
                  >
                    <Check className="h-4 w-4" />
                  </CheckboxPrimitive.Indicator>
                </CheckboxPrimitive.Root>
              ))
              Checkbox.displayName = CheckboxPrimitive.Root.displayName

              export { Checkbox }


              
              `,
            },
            '/components/ui/collapsible.tsx': {
              code: `
              import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"

              const Collapsible = CollapsiblePrimitive.Root

              const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger

              const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent

              export { Collapsible, CollapsibleTrigger, CollapsibleContent }


              `,
            },
            '/components/ui/dialog.tsx': {
              code: `
              import * as React from "react"
              import * as DialogPrimitive from "@radix-ui/react-dialog"
              import { X } from "lucide-react"

              import { cn } from "../../lib/utils"

              const Dialog = DialogPrimitive.Root

              const DialogTrigger = DialogPrimitive.Trigger

              const DialogPortal = DialogPrimitive.Portal

              const DialogClose = DialogPrimitive.Close

              const DialogOverlay = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Overlay>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
              >(({ className, ...props }, ref) => (
                <DialogPrimitive.Overlay
                  ref={ref}
                  className={cn(
                    "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
                    className
                  )}
                  {...props}
                />
              ))
              DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

              const DialogContent = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Content>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
              >(({ className, children, ...props }, ref) => (
                <DialogPortal>
                  <DialogOverlay />
                  <DialogPrimitive.Content
                    ref={ref}
                    className={cn(
                      "fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-gray-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-gray-800 dark:bg-gray-950",
                      className
                    )}
                    {...props}
                  >
                    {children}
                    <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-gray-100 data-[state=open]:text-gray-500 dark:ring-offset-gray-950 dark:focus:ring-gray-300 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-400">
                      <X className="h-4 w-4" />
                      <span className="sr-only">Close</span>
                    </DialogPrimitive.Close>
                  </DialogPrimitive.Content>
                </DialogPortal>
              ))
              DialogContent.displayName = DialogPrimitive.Content.displayName

              const DialogHeader = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLDivElement>) => (
                <div
                  className={cn(
                    "flex flex-col space-y-1.5 text-center sm:text-left",
                    className
                  )}
                  {...props}
                />
              )
              DialogHeader.displayName = "DialogHeader"

              const DialogFooter = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLDivElement>) => (
                <div
                  className={cn(
                    "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
                    className
                  )}
                  {...props}
                />
              )
              DialogFooter.displayName = "DialogFooter"

              const DialogTitle = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Title>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
              >(({ className, ...props }, ref) => (
                <DialogPrimitive.Title
                  ref={ref}
                  className={cn(
                    "text-lg font-semibold leading-none tracking-tight",
                    className
                  )}
                  {...props}
                />
              ))
              DialogTitle.displayName = DialogPrimitive.Title.displayName

              const DialogDescription = React.forwardRef<
                React.ElementRef<typeof DialogPrimitive.Description>,
                React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
              >(({ className, ...props }, ref) => (
                <DialogPrimitive.Description
                  ref={ref}
                  className={cn("text-sm text-gray-500 dark:text-gray-400", className)}
                  {...props}
                />
              ))
              DialogDescription.displayName = DialogPrimitive.Description.displayName

              export {
                Dialog,
                DialogPortal,
                DialogOverlay,
                DialogClose,
                DialogTrigger,
                DialogContent,
                DialogHeader,
                DialogFooter,
                DialogTitle,
                DialogDescription,
              }


              
              `,
            },
            '/components/ui/drawer.tsx': {
              code: `
              import * as React from "react"
              import { Drawer as DrawerPrimitive } from "vaul"

              import { cn } from "../../lib/utils"

              const Drawer = ({
                shouldScaleBackground = true,
                ...props
              }: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
                <DrawerPrimitive.Root
                  shouldScaleBackground={shouldScaleBackground}
                  {...props}
                />
              )
              Drawer.displayName = "Drawer"

              const DrawerTrigger = DrawerPrimitive.Trigger

              const DrawerPortal = DrawerPrimitive.Portal

              const DrawerClose = DrawerPrimitive.Close

              const DrawerOverlay = React.forwardRef<
                React.ElementRef<typeof DrawerPrimitive.Overlay>,
                React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
              >(({ className, ...props }, ref) => (
                <DrawerPrimitive.Overlay
                  ref={ref}
                  className={cn("fixed inset-0 z-50 bg-black/80", className)}
                  {...props}
                />
              ))
              DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

              const DrawerContent = React.forwardRef<
                React.ElementRef<typeof DrawerPrimitive.Content>,
                React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
              >(({ className, children, ...props }, ref) => (
                <DrawerPortal>
                  <DrawerOverlay />
                  <DrawerPrimitive.Content
                    ref={ref}
                    className={cn(
                      "fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border border-gray-200 bg-white dark:border-gray-800 dark:bg-gray-950",
                      className
                    )}
                    {...props}
                  >
                    <div className="mx-auto mt-4 h-2 w-[100px] rounded-full bg-gray-100 dark:bg-gray-800" />
                    {children}
                  </DrawerPrimitive.Content>
                </DrawerPortal>
              ))
              DrawerContent.displayName = "DrawerContent"

              const DrawerHeader = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLDivElement>) => (
                <div
                  className={cn("grid gap-1.5 p-4 text-center sm:text-left", className)}
                  {...props}
                />
              )
              DrawerHeader.displayName = "DrawerHeader"

              const DrawerFooter = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLDivElement>) => (
                <div
                  className={cn("mt-auto flex flex-col gap-2 p-4", className)}
                  {...props}
                />
              )
              DrawerFooter.displayName = "DrawerFooter"

              const DrawerTitle = React.forwardRef<
                React.ElementRef<typeof DrawerPrimitive.Title>,
                React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
              >(({ className, ...props }, ref) => (
                <DrawerPrimitive.Title
                  ref={ref}
                  className={cn(
                    "text-lg font-semibold leading-none tracking-tight",
                    className
                  )}
                  {...props}
                />
              ))
              DrawerTitle.displayName = DrawerPrimitive.Title.displayName

              const DrawerDescription = React.forwardRef<
                React.ElementRef<typeof DrawerPrimitive.Description>,
                React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
              >(({ className, ...props }, ref) => (
                <DrawerPrimitive.Description
                  ref={ref}
                  className={cn("text-sm text-gray-500 dark:text-gray-400", className)}
                  {...props}
                />
              ))
              DrawerDescription.displayName = DrawerPrimitive.Description.displayName

              export {
                Drawer,
                DrawerPortal,
                DrawerOverlay,
                DrawerTrigger,
                DrawerClose,
                DrawerContent,
                DrawerHeader,
                DrawerFooter,
                DrawerTitle,
                DrawerDescription,
              }


              
              `,
            },
            '/components/ui/dropdown-menu.tsx': {
              code: `
              import * as React from "react"
              import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
              import { Check, ChevronRight, Circle } from "lucide-react"

              import { cn } from "../../lib/utils"

              const DropdownMenu = DropdownMenuPrimitive.Root

              const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

              const DropdownMenuGroup = DropdownMenuPrimitive.Group

              const DropdownMenuPortal = DropdownMenuPrimitive.Portal

              const DropdownMenuSub = DropdownMenuPrimitive.Sub

              const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

              const DropdownMenuSubTrigger = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
                  inset?: boolean
                }
              >(({ className, inset, children, ...props }, ref) => (
                <DropdownMenuPrimitive.SubTrigger
                  ref={ref}
                  className={cn(
                    "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-gray-100 data-[state=open]:bg-gray-100 dark:focus:bg-gray-800 dark:data-[state=open]:bg-gray-800",
                    inset && "pl-8",
                    className
                  )}
                  {...props}
                >
                  {children}
                  <ChevronRight className="ml-auto h-4 w-4" />
                </DropdownMenuPrimitive.SubTrigger>
              ))
              DropdownMenuSubTrigger.displayName =
                DropdownMenuPrimitive.SubTrigger.displayName

              const DropdownMenuSubContent = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
              >(({ className, ...props }, ref) => (
                <DropdownMenuPrimitive.SubContent
                  ref={ref}
                  className={cn(
                    "z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white p-1 text-gray-950 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                    className
                  )}
                  {...props}
                />
              ))
              DropdownMenuSubContent.displayName =
                DropdownMenuPrimitive.SubContent.displayName

              const DropdownMenuContent = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.Content>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
              >(({ className, sideOffset = 4, ...props }, ref) => (
                <DropdownMenuPrimitive.Portal>
                  <DropdownMenuPrimitive.Content
                    ref={ref}
                    sideOffset={sideOffset}
                    className={cn(
                      "z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white p-1 text-gray-950 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                      className
                    )}
                    {...props}
                  />
                </DropdownMenuPrimitive.Portal>
              ))
              DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

              const DropdownMenuItem = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.Item>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
                  inset?: boolean
                }
              >(({ className, inset, ...props }, ref) => (
                <DropdownMenuPrimitive.Item
                  ref={ref}
                  className={cn(
                    "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    inset && "pl-8",
                    className
                  )}
                  {...props}
                />
              ))
              DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

              const DropdownMenuCheckboxItem = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
              >(({ className, children, checked, ...props }, ref) => (
                <DropdownMenuPrimitive.CheckboxItem
                  ref={ref}
                  className={cn(
                    "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    className
                  )}
                  checked={checked}
                  {...props}
                >
                  <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                    <DropdownMenuPrimitive.ItemIndicator>
                      <Check className="h-4 w-4" />
                    </DropdownMenuPrimitive.ItemIndicator>
                  </span>
                  {children}
                </DropdownMenuPrimitive.CheckboxItem>
              ))
              DropdownMenuCheckboxItem.displayName =
                DropdownMenuPrimitive.CheckboxItem.displayName

              const DropdownMenuRadioItem = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
              >(({ className, children, ...props }, ref) => (
                <DropdownMenuPrimitive.RadioItem
                  ref={ref}
                  className={cn(
                    "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    className
                  )}
                  {...props}
                >
                  <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                    <DropdownMenuPrimitive.ItemIndicator>
                      <Circle className="h-2 w-2 fill-current" />
                    </DropdownMenuPrimitive.ItemIndicator>
                  </span>
                  {children}
                </DropdownMenuPrimitive.RadioItem>
              ))
              DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName

              const DropdownMenuLabel = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.Label>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
                  inset?: boolean
                }
              >(({ className, inset, ...props }, ref) => (
                <DropdownMenuPrimitive.Label
                  ref={ref}
                  className={cn(
                    "px-2 py-1.5 text-sm font-semibold",
                    inset && "pl-8",
                    className
                  )}
                  {...props}
                />
              ))
              DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName

              const DropdownMenuSeparator = React.forwardRef<
                React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
                React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
              >(({ className, ...props }, ref) => (
                <DropdownMenuPrimitive.Separator
                  ref={ref}
                  className={cn("-mx-1 my-1 h-px bg-gray-100 dark:bg-gray-800", className)}
                  {...props}
                />
              ))
              DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

              const DropdownMenuShortcut = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLSpanElement>) => {
                return (
                  <span
                    className={cn("ml-auto text-xs tracking-widest opacity-60", className)}
                    {...props}
                  />
                )
              }
              DropdownMenuShortcut.displayName = "DropdownMenuShortcut"

              export {
                DropdownMenu,
                DropdownMenuTrigger,
                DropdownMenuContent,
                DropdownMenuItem,
                DropdownMenuCheckboxItem,
                DropdownMenuRadioItem,
                DropdownMenuLabel,
                DropdownMenuSeparator,
                DropdownMenuShortcut,
                DropdownMenuGroup,
                DropdownMenuPortal,
                DropdownMenuSub,
                DropdownMenuSubContent,
                DropdownMenuSubTrigger,
                DropdownMenuRadioGroup,
              }


              
              `,
            },
              '/components/ui/input.tsx': {
                code: `
                import * as React from "react"
                import { cn } from "../../lib/utils"

                export interface InputProps
                  extends React.InputHTMLAttributes<HTMLInputElement> {}

                const Input = React.forwardRef<HTMLInputElement, InputProps>(
                  ({ className, type, ...props }, ref) => {
                    return (
                      <input
                        type={type}
                        className={cn(
                          "flex h-10 w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
                          className
                        )}
                        ref={ref}
                        {...props}
                      />
                    )
                  }
                )
                Input.displayName = "Input"

                export { Input }

                
                `,
              },


            '/components/ui/label.tsx': {
              code: `
              import * as React from 'react';
              import * as LabelPrimitive from '@radix-ui/react-label';
              import { cva, type VariantProps } from 'class-variance-authority';

              import { cn } from '../../lib/utils';

              const labelVariants = cva(
                "text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              )

              const Label = React.forwardRef<
                React.ElementRef<typeof LabelPrimitive.Root>,
                React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
                  VariantProps<typeof labelVariants>
              >(({ className, ...props }, ref) => (
                <LabelPrimitive.Root
                  ref={ref}
                  className={cn(labelVariants(), className)}
                  {...props}
                />
              ))
              Label.displayName = LabelPrimitive.Root.displayName

              export { Label }

              
              `,
            },
            '/components/ui/menubar.tsx': {
              code: `
              
              import * as React from "react"
              import * as MenubarPrimitive from "@radix-ui/react-menubar"
              import { Check, ChevronRight, Circle } from "lucide-react"

              import { cn } from "../../lib/utils"

              const MenubarMenu = MenubarPrimitive.Menu

              const MenubarGroup = MenubarPrimitive.Group

              const MenubarPortal = MenubarPrimitive.Portal

              const MenubarSub = MenubarPrimitive.Sub

              const MenubarRadioGroup = MenubarPrimitive.RadioGroup

              const Menubar = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.Root>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Root>
              >(({ className, ...props }, ref) => (
                <MenubarPrimitive.Root
                  ref={ref}
                  className={cn(
                    "flex h-10 items-center space-x-1 rounded-md border border-gray-200 bg-white p-1 dark:border-gray-800 dark:bg-gray-950",
                    className
                  )}
                  {...props}
                />
              ))
              Menubar.displayName = MenubarPrimitive.Root.displayName

              const MenubarTrigger = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.Trigger>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Trigger>
              >(({ className, ...props }, ref) => (
                <MenubarPrimitive.Trigger
                  ref={ref}
                  className={cn(
                    "flex cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none focus:bg-gray-100 focus:text-gray-900 data-[state=open]:bg-gray-100 data-[state=open]:text-gray-900 dark:focus:bg-gray-800 dark:focus:text-gray-50 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-50",
                    className
                  )}
                  {...props}
                />
              ))
              MenubarTrigger.displayName = MenubarPrimitive.Trigger.displayName

              const MenubarSubTrigger = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.SubTrigger>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.SubTrigger> & {
                  inset?: boolean
                }
              >(({ className, inset, children, ...props }, ref) => (
                <MenubarPrimitive.SubTrigger
                  ref={ref}
                  className={cn(
                    "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-gray-100 focus:text-gray-900 data-[state=open]:bg-gray-100 data-[state=open]:text-gray-900 dark:focus:bg-gray-800 dark:focus:text-gray-50 dark:data-[state=open]:bg-gray-800 dark:data-[state=open]:text-gray-50",
                    inset && "pl-8",
                    className
                  )}
                  {...props}
                >
                  {children}
                  <ChevronRight className="ml-auto h-4 w-4" />
                </MenubarPrimitive.SubTrigger>
              ))
              MenubarSubTrigger.displayName = MenubarPrimitive.SubTrigger.displayName

              const MenubarSubContent = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.SubContent>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.SubContent>
              >(({ className, ...props }, ref) => (
                <MenubarPrimitive.SubContent
                  ref={ref}
                  className={cn(
                    "z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white p-1 text-gray-950 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                    className
                  )}
                  {...props}
                />
              ))
              MenubarSubContent.displayName = MenubarPrimitive.SubContent.displayName

              const MenubarContent = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.Content>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Content>
              >(
                (
                  { className, align = "start", alignOffset = -4, sideOffset = 8, ...props },
                  ref
                ) => (
                  <MenubarPrimitive.Portal>
                    <MenubarPrimitive.Content
                      ref={ref}
                      align={align}
                      alignOffset={alignOffset}
                      sideOffset={sideOffset}
                      className={cn(
                        "z-50 min-w-[12rem] overflow-hidden rounded-md border border-gray-200 bg-white p-1 text-gray-950 shadow-md data-[state=open]:animate-in data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                        className
                      )}
                      {...props}
                    />
                  </MenubarPrimitive.Portal>
                )
              )
              MenubarContent.displayName = MenubarPrimitive.Content.displayName

              const MenubarItem = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.Item>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Item> & {
                  inset?: boolean
                }
              >(({ className, inset, ...props }, ref) => (
                <MenubarPrimitive.Item
                  ref={ref}
                  className={cn(
                    "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    inset && "pl-8",
                    className
                  )}
                  {...props}
                />
              ))
              MenubarItem.displayName = MenubarPrimitive.Item.displayName

              const MenubarCheckboxItem = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.CheckboxItem>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.CheckboxItem>
              >(({ className, children, checked, ...props }, ref) => (
                <MenubarPrimitive.CheckboxItem
                  ref={ref}
                  className={cn(
                    "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    className
                  )}
                  checked={checked}
                  {...props}
                >
                  <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                    <MenubarPrimitive.ItemIndicator>
                      <Check className="h-4 w-4" />
                    </MenubarPrimitive.ItemIndicator>
                  </span>
                  {children}
                </MenubarPrimitive.CheckboxItem>
              ))
              MenubarCheckboxItem.displayName = MenubarPrimitive.CheckboxItem.displayName

              const MenubarRadioItem = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.RadioItem>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.RadioItem>
              >(({ className, children, ...props }, ref) => (
                <MenubarPrimitive.RadioItem
                  ref={ref}
                  className={cn(
                    "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    className
                  )}
                  {...props}
                >
                  <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                    <MenubarPrimitive.ItemIndicator>
                      <Circle className="h-2 w-2 fill-current" />
                    </MenubarPrimitive.ItemIndicator>
                  </span>
                  {children}
                </MenubarPrimitive.RadioItem>
              ))
              MenubarRadioItem.displayName = MenubarPrimitive.RadioItem.displayName

              const MenubarLabel = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.Label>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Label> & {
                  inset?: boolean
                }
              >(({ className, inset, ...props }, ref) => (
                <MenubarPrimitive.Label
                  ref={ref}
                  className={cn(
                    "px-2 py-1.5 text-sm font-semibold",
                    inset && "pl-8",
                    className
                  )}
                  {...props}
                />
              ))
              MenubarLabel.displayName = MenubarPrimitive.Label.displayName

              const MenubarSeparator = React.forwardRef<
                React.ElementRef<typeof MenubarPrimitive.Separator>,
                React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Separator>
              >(({ className, ...props }, ref) => (
                <MenubarPrimitive.Separator
                  ref={ref}
                  className={cn("-mx-1 my-1 h-px bg-gray-100 dark:bg-gray-800", className)}
                  {...props}
                />
              ))
              MenubarSeparator.displayName = MenubarPrimitive.Separator.displayName

              const MenubarShortcut = ({
                className,
                ...props
              }: React.HTMLAttributes<HTMLSpanElement>) => {
                return (
                  <span
                    className={cn(
                      "ml-auto text-xs tracking-widest text-gray-500 dark:text-gray-400",
                      className
                    )}
                    {...props}
                  />
                )
              }
              MenubarShortcut.displayname = "MenubarShortcut"

              export {
                Menubar,
                MenubarMenu,
                MenubarTrigger,
                MenubarContent,
                MenubarItem,
                MenubarSeparator,
                MenubarLabel,
                MenubarCheckboxItem,
                MenubarRadioGroup,
                MenubarRadioItem,
                MenubarPortal,
                MenubarSubContent,
                MenubarSubTrigger,
                MenubarGroup,
                MenubarSub,
                MenubarShortcut,
              }


              
              `,
            },
          '/components/ui/navigation-menu.tsx': {
            code: `
            import * as React from "react"
            import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu"
            import { cva } from "class-variance-authority"
            import { ChevronDown } from "lucide-react"

            import { cn } from "../../lib/utils"

            const NavigationMenu = React.forwardRef<
              React.ElementRef<typeof NavigationMenuPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
            >(({ className, children, ...props }, ref) => (
              <NavigationMenuPrimitive.Root
                ref={ref}
                className={cn(
                  "relative z-10 flex max-w-max flex-1 items-center justify-center",
                  className
                )}
                {...props}
              >
                {children}
                <NavigationMenuViewport />
              </NavigationMenuPrimitive.Root>
            ))
            NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName

            const NavigationMenuList = React.forwardRef<
              React.ElementRef<typeof NavigationMenuPrimitive.List>,
              React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
            >(({ className, ...props }, ref) => (
              <NavigationMenuPrimitive.List
                ref={ref}
                className={cn(
                  "group flex flex-1 list-none items-center justify-center space-x-1",
                  className
                )}
                {...props}
              />
            ))
            NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName

            const NavigationMenuItem = NavigationMenuPrimitive.Item

            const navigationMenuTriggerStyle = cva(
              "group inline-flex h-10 w-max items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-medium transition-colors hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-gray-100/50 data-[state=open]:bg-gray-100/50 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus:bg-gray-800 dark:focus:text-gray-50 dark:data-[active]:bg-gray-800/50 dark:data-[state=open]:bg-gray-800/50"
            )

            const NavigationMenuTrigger = React.forwardRef<
              React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
              React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
            >(({ className, children, ...props }, ref) => (
              <NavigationMenuPrimitive.Trigger
                ref={ref}
                className={cn(navigationMenuTriggerStyle(), "group", className)}
                {...props}
              >
                {children}{""}
                <ChevronDown
                  className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
                  aria-hidden="true"
                />
              </NavigationMenuPrimitive.Trigger>
            ))
            NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName

            const NavigationMenuContent = React.forwardRef<
              React.ElementRef<typeof NavigationMenuPrimitive.Content>,
              React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content>
            >(({ className, ...props }, ref) => (
              <NavigationMenuPrimitive.Content
                ref={ref}
                className={cn(
                  "left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto",
                  className
                )}
                {...props}
              />
            ))
            NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName

            const NavigationMenuLink = NavigationMenuPrimitive.Link

            const NavigationMenuViewport = React.forwardRef<
              React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
              React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Viewport>
            >(({ className, ...props }, ref) => (
              <div className={cn("absolute left-0 top-full flex justify-center")}>
                <NavigationMenuPrimitive.Viewport
                  className={cn(
                    "origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border border-gray-200 bg-white text-gray-950 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)] dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                    className
                  )}
                  ref={ref}
                  {...props}
                />
              </div>
            ))
            NavigationMenuViewport.displayName =
              NavigationMenuPrimitive.Viewport.displayName

            const NavigationMenuIndicator = React.forwardRef<
              React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
              React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
            >(({ className, ...props }, ref) => (
              <NavigationMenuPrimitive.Indicator
                ref={ref}
                className={cn(
                  "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
                  className
                )}
                {...props}
              >
                <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-gray-200 shadow-md dark:bg-gray-800" />
              </NavigationMenuPrimitive.Indicator>
            ))
            NavigationMenuIndicator.displayName =
              NavigationMenuPrimitive.Indicator.displayName

            export {
              navigationMenuTriggerStyle,
              NavigationMenu,
              NavigationMenuList,
              NavigationMenuItem,
              NavigationMenuContent,
              NavigationMenuTrigger,
              NavigationMenuLink,
              NavigationMenuIndicator,
              NavigationMenuViewport,
            }


            `,
          },
          '/components/ui/pagination.tsx': {
            code: `
            import * as React from "react"
            import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react"

            import { cn } from "../../lib/utils"
            import { ButtonProps, buttonVariants } from "./button"

            const Pagination = ({ className, ...props }: React.ComponentProps<"nav">) => (
              <nav
                role="navigation"
                aria-label="pagination"
                className={cn("mx-auto flex w-full justify-center", className)}
                {...props}
              />
            )
            Pagination.displayName = "Pagination"

            const PaginationContent = React.forwardRef<
              HTMLUListElement,
              React.ComponentProps<"ul">
            >(({ className, ...props }, ref) => (
              <ul
                ref={ref}
                className={cn("flex flex-row items-center gap-1", className)}
                {...props}
              />
            ))
            PaginationContent.displayName = "PaginationContent"

            const PaginationItem = React.forwardRef<
              HTMLLIElement,
              React.ComponentProps<"li">
            >(({ className, ...props }, ref) => (
              <li ref={ref} className={cn("", className)} {...props} />
            ))
            PaginationItem.displayName = "PaginationItem"

            type PaginationLinkProps = {
              isActive?: boolean
            } & Pick<ButtonProps, "size"> &
              React.ComponentProps<"a">

            const PaginationLink = ({
              className,
              isActive,
              size = "icon",
              ...props
            }: PaginationLinkProps) => (
              <a
                aria-current={isActive ? "page" : undefined}
                className={cn(
                  buttonVariants({
                    variant: isActive ? "outline" : "ghost",
                    size,
                  }),
                  className
                )}
                {...props}
              />
            )
            PaginationLink.displayName = "PaginationLink"

            const PaginationPrevious = ({
              className,
              ...props
            }: React.ComponentProps<typeof PaginationLink>) => (
              <PaginationLink
                aria-label="Go to previous page"
                size="default"
                className={cn("gap-1 pl-2.5", className)}
                {...props}
              >
                <ChevronLeft className="h-4 w-4" />
                <span>Previous</span>
              </PaginationLink>
            )
            PaginationPrevious.displayName = "PaginationPrevious"

            const PaginationNext = ({
              className,
              ...props
            }: React.ComponentProps<typeof PaginationLink>) => (
              <PaginationLink
                aria-label="Go to next page"
                size="default"
                className={cn("gap-1 pr-2.5", className)}
                {...props}
              >
                <span>Next</span>
                <ChevronRight className="h-4 w-4" />
              </PaginationLink>
            )
            PaginationNext.displayName = "PaginationNext"

            const PaginationEllipsis = ({
              className,
              ...props
            }: React.ComponentProps<"span">) => (
              <span
                aria-hidden
                className={cn("flex h-9 w-9 items-center justify-center", className)}
                {...props}
              >
                <MoreHorizontal className="h-4 w-4" />
                <span className="sr-only">More pages</span>
              </span>
            )
            PaginationEllipsis.displayName = "PaginationEllipsis"

            export {
              Pagination,
              PaginationContent,
              PaginationEllipsis,
              PaginationItem,
              PaginationLink,
              PaginationNext,
              PaginationPrevious,
            }


            
            `,
          },
          '/components/ui/popover.tsx': {
            code: `
            import * as React from "react"
            import * as PopoverPrimitive from "@radix-ui/react-popover"

            import { cn } from "../../lib/utils"

            const Popover = PopoverPrimitive.Root

            const PopoverTrigger = PopoverPrimitive.Trigger

            const PopoverContent = React.forwardRef<
              React.ElementRef<typeof PopoverPrimitive.Content>,
              React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
            >(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
              <PopoverPrimitive.Portal>
                <PopoverPrimitive.Content
                  ref={ref}
                  align={align}
                  sideOffset={sideOffset}
                  className={cn(
                    "z-50 w-72 rounded-md border border-gray-200 bg-white p-4 text-gray-950 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                    className
                  )}
                  {...props}
                />
              </PopoverPrimitive.Portal>
            ))
            PopoverContent.displayName = PopoverPrimitive.Content.displayName

            export { Popover, PopoverTrigger, PopoverContent }


            `,
          },

          '/components/ui/progress.tsx': {
            code: `
            import * as React from "react"
            import * as ProgressPrimitive from "@radix-ui/react-progress"

            import { cn } from "../../lib/utils"

            const Progress = React.forwardRef<
              React.ElementRef<typeof ProgressPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
            >(({ className, value, ...props }, ref) => (
              <ProgressPrimitive.Root
                ref={ref}
                className={cn(
                  "relative h-4 w-full overflow-hidden rounded-full bg-gray-100 dark:bg-gray-800",
                  className
                )}
                {...props}
              >
                <ProgressPrimitive.Indicator
                  className="h-full w-full flex-1 bg-gray-900 transition-all dark:bg-gray-50"
                  
                  

                />
              </ProgressPrimitive.Root>
            ))
            Progress.displayName = ProgressPrimitive.Root.displayName

            export { Progress }

            
            `,
          },

          '/components/ui/radio-group.tsx': {
            code: `
            import * as React from "react"
            import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
            import { Circle } from "lucide-react"

            import { cn } from "../../lib/utils"

            const RadioGroup = React.forwardRef<
              React.ElementRef<typeof RadioGroupPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
            >(({ className, ...props }, ref) => {
              return (
                <RadioGroupPrimitive.Root
                  className={cn("grid gap-2", className)}
                  {...props}
                  ref={ref}
                />
              )
            })
            RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

            const RadioGroupItem = React.forwardRef<
              React.ElementRef<typeof RadioGroupPrimitive.Item>,
              React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
            >(({ className, ...props }, ref) => {
              return (
                <RadioGroupPrimitive.Item
                  ref={ref}
                  className={cn(
                    "aspect-square h-4 w-4 rounded-full border border-gray-200 dark:border-gray-900 text-gray-900 ring-offset-white focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  dark:text-gray-50 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
                    className
                  )}
                  {...props}
                >
                  <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
                    <Circle className="h-2.5 w-2.5 fill-current text-current" />
                  </RadioGroupPrimitive.Indicator>
                </RadioGroupPrimitive.Item>
              )
            })
            RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

            export { RadioGroup, RadioGroupItem }


            `,
          },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          '/components/ui/select.tsx': {
            code: `
              import * as React from "react"
              import * as SelectPrimitive from "@radix-ui/react-select"
              import { Check, ChevronDown, ChevronUp } from "lucide-react"

              import { cn } from "../../lib/utils"

              const Select = SelectPrimitive.Root

              const SelectGroup = SelectPrimitive.Group

              const SelectValue = SelectPrimitive.Value

              const SelectTrigger = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.Trigger>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
              >(({ className, children, ...props }, ref) => (
                <SelectPrimitive.Trigger
                  ref={ref}
                  className={cn(
                    "flex h-10 w-full items-center justify-between rounded-md border border-gray-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus:ring-gray-300",
                    className
                  )}
                  {...props}
                >
                  {children}
                  <SelectPrimitive.Icon asChild>
                    <ChevronDown className="h-4 w-4 opacity-50" />
                  </SelectPrimitive.Icon>
                </SelectPrimitive.Trigger>
              ))
              SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

              const SelectScrollUpButton = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
              >(({ className, ...props }, ref) => (
                <SelectPrimitive.ScrollUpButton
                  ref={ref}
                  className={cn(
                    "flex cursor-default items-center justify-center py-1",
                    className
                  )}
                  {...props}
                >
                  <ChevronUp className="h-4 w-4" />
                </SelectPrimitive.ScrollUpButton>
              ))
              SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

              const SelectScrollDownButton = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
              >(({ className, ...props }, ref) => (
                <SelectPrimitive.ScrollDownButton
                  ref={ref}
                  className={cn(
                    "flex cursor-default items-center justify-center py-1",
                    className
                  )}
                  {...props}
                >
                  <ChevronDown className="h-4 w-4" />
                </SelectPrimitive.ScrollDownButton>
              ))
              SelectScrollDownButton.displayName =
                SelectPrimitive.ScrollDownButton.displayName

              const SelectContent = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.Content>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
              >(({ className, children, position = "popper", ...props }, ref) => (
                <SelectPrimitive.Portal>
                  <SelectPrimitive.Content
                    ref={ref}
                    className={cn(
                      "relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white text-gray-950 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                      position === "popper" &&
                        "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                      className
                    )}
                    position={position}
                    {...props}
                  >
                    <SelectScrollUpButton />
                    <SelectPrimitive.Viewport
                      className={cn(
                        "p-1",
                        position === "popper" &&
                          "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]"
                      )}
                    >
                      {children}
                    </SelectPrimitive.Viewport>
                    <SelectScrollDownButton />
                  </SelectPrimitive.Content>
                </SelectPrimitive.Portal>
              ))
              SelectContent.displayName = SelectPrimitive.Content.displayName

              const SelectLabel = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.Label>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
              >(({ className, ...props }, ref) => (
                <SelectPrimitive.Label
                  ref={ref}
                  className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)}
                  {...props}
                />
              ))
              SelectLabel.displayName = SelectPrimitive.Label.displayName

              const SelectItem = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.Item>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
              >(({ className, children, ...props }, ref) => (
                <SelectPrimitive.Item
                  ref={ref}
                  className={cn(
                    "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-gray-100 focus:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-gray-800 dark:focus:text-gray-50",
                    className
                  )}
                  {...props}
                >
                  <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                    <SelectPrimitive.ItemIndicator>
                      <Check className="h-4 w-4" />
                    </SelectPrimitive.ItemIndicator>
                  </span>

                  <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
                </SelectPrimitive.Item>
              ))
              SelectItem.displayName = SelectPrimitive.Item.displayName

              const SelectSeparator = React.forwardRef<
                React.ElementRef<typeof SelectPrimitive.Separator>,
                React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
              >(({ className, ...props }, ref) => (
                <SelectPrimitive.Separator
                  ref={ref}
                  className={cn("-mx-1 my-1 h-px bg-gray-100 dark:bg-gray-800", className)}
                  {...props}
                />
              ))
              SelectSeparator.displayName = SelectPrimitive.Separator.displayName

              export {
                Select,
                SelectGroup,
                SelectValue,
                SelectTrigger,
                SelectContent,
                SelectLabel,
                SelectItem,
                SelectSeparator,
                SelectScrollUpButton,
                SelectScrollDownButton,
              }



              
            `,
          },
/// separator listo xd
          '/components/ui/separator.tsx': {
            code: `
            import * as React from "react"
            import * as SeparatorPrimitive from "@radix-ui/react-separator"

            import { cn } from "../../lib/utils"

            const Separator = React.forwardRef<
              React.ElementRef<typeof SeparatorPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
            >(
              (
                { className, orientation = "horizontal", decorative = true, ...props },
                ref
              ) => (
                <SeparatorPrimitive.Root
                  ref={ref}
                  decorative={decorative}
                  orientation={orientation}
                  className={cn(
                    "shrink-0 bg-gray-200 dark:bg-gray-800",
                    orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
                    className
                  )}
                  {...props}
                />
              )
            )
            Separator.displayName = SeparatorPrimitive.Root.displayName

            export { Separator }
            `,
          },
/// skeleton listo xd
          '/components/ui/skeleton.tsx': {
            code: `
            import { cn } from "../../lib/utils"

            function Skeleton({
              className,
              ...props
            }: React.HTMLAttributes<HTMLDivElement>) {
              return (
                <div
                  className={cn("animate-pulse rounded-md bg-gray-100 dark:bg-gray-800", className)}
                  {...props}
                />
              )
            }

            export { Skeleton }

            `,
          },
/// slide listo xd
          '/components/ui/slider.tsx': {
            code: `
            import * as React from "react"
            import * as SliderPrimitive from "@radix-ui/react-slider"

            import { cn } from "../../lib/utils"

            const Slider = React.forwardRef<
              React.ElementRef<typeof SliderPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
            >(({ className, ...props }, ref) => (
              <SliderPrimitive.Root
                ref={ref}
                className={cn(
                  "relative flex w-full touch-none select-none items-center",
                  className
                )}
                {...props}
              >
                <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-gray-100 dark:bg-gray-800">
                  <SliderPrimitive.Range className="absolute h-full bg-gray-900 dark:bg-gray-50" />
                </SliderPrimitive.Track>
                <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-gray-900 bg-white ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-50 dark:bg-gray-950 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300" />
              </SliderPrimitive.Root>
            ))
            Slider.displayName = SliderPrimitive.Root.displayName

            export { Slider }


            `,
          },
/// witch listo xd
          '/components/ui/switch.tsx': {
            code: `
            import * as React from "react"
            import * as SwitchPrimitives from "@radix-ui/react-switch"

            import { cn } from "../../lib/utils"

            const Switch = React.forwardRef<
              React.ElementRef<typeof SwitchPrimitives.Root>,
              React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
            >(({ className, ...props }, ref) => (
              <SwitchPrimitives.Root
                className={cn(
                  "peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-gray-900 data-[state=unchecked]:bg-gray-200 dark:focus-visible:ring-gray-300 dark:focus-visible:ring-offset-gray-950 dark:data-[state=checked]:bg-gray-50 dark:data-[state=unchecked]:bg-gray-800",
                  className
                )}
                {...props}
                ref={ref}
              >
                <SwitchPrimitives.Thumb
                  className={cn(
                    "pointer-events-none block h-5 w-5 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 dark:bg-gray-950"
                  )}
                />
              </SwitchPrimitives.Root>
            ))
            Switch.displayName = SwitchPrimitives.Root.displayName

            export { Switch }


            `,
          },
/// table listo xd
          '/components/ui/table.tsx': {
            code: `
            import * as React from "react"

            import { cn } from "../../lib/utils"

            const Table = React.forwardRef<
              HTMLTableElement,
              React.HTMLAttributes<HTMLTableElement>
            >(({ className, ...props }, ref) => (
              <div className="relative w-full overflow-auto">
                <table
                  ref={ref}
                  className={cn("w-full caption-bottom text-sm", className)}
                  {...props}
                />
              </div>
            ))
            Table.displayName = "Table"

            const TableHeader = React.forwardRef<
              HTMLTableSectionElement,
              React.HTMLAttributes<HTMLTableSectionElement>
            >(({ className, ...props }, ref) => (
              <thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />
            ))
            TableHeader.displayName = "TableHeader"

            const TableBody = React.forwardRef<
              HTMLTableSectionElement,
              React.HTMLAttributes<HTMLTableSectionElement>
            >(({ className, ...props }, ref) => (
              <tbody
                ref={ref}
                className={cn("[&_tr:last-child]:border-0", className)}
                {...props}
              />
            ))
            TableBody.displayName = "TableBody"

            const TableFooter = React.forwardRef<
              HTMLTableSectionElement,
              React.HTMLAttributes<HTMLTableSectionElement>
            >(({ className, ...props }, ref) => (
              <tfoot
                ref={ref}
                className={cn(
                  "border-t bg-gray-100/50 font-medium [&>tr]:last:border-b-0 dark:bg-gray-800/50",
                  className
                )}
                {...props}
              />
            ))
            TableFooter.displayName = "TableFooter"

            const TableRow = React.forwardRef<
              HTMLTableRowElement,
              React.HTMLAttributes<HTMLTableRowElement>
            >(({ className, ...props }, ref) => (
              <tr
                ref={ref}
                className={cn(
                  "border-b transition-colors hover:bg-gray-100/50 data-[state=selected]:bg-gray-100 dark:hover:bg-gray-800/50 dark:data-[state=selected]:bg-gray-800",
                  className
                )}
                {...props}
              />
            ))
            TableRow.displayName = "TableRow"

            const TableHead = React.forwardRef<
              HTMLTableCellElement,
              React.ThHTMLAttributes<HTMLTableCellElement>
            >(({ className, ...props }, ref) => (
              <th
                ref={ref}
                className={cn(
                  "h-12 px-4 text-left align-middle font-medium text-gray-500 [&:has([role=checkbox])]:pr-0 dark:text-gray-400",
                  className
                )}
                {...props}
              />
            ))
            TableHead.displayName = "TableHead"

            const TableCell = React.forwardRef<
              HTMLTableCellElement,
              React.TdHTMLAttributes<HTMLTableCellElement>
            >(({ className, ...props }, ref) => (
              <td
                ref={ref}
                className={cn("p-4 align-middle [&:has([role=checkbox])]:pr-0", className)}
                {...props}
              />
            ))
            TableCell.displayName = "TableCell"

            const TableCaption = React.forwardRef<
              HTMLTableCaptionElement,
              React.HTMLAttributes<HTMLTableCaptionElement>
            >(({ className, ...props }, ref) => (
              <caption
                ref={ref}
                className={cn("mt-4 text-sm text-gray-500 dark:text-gray-400", className)}
                {...props}
              />
            ))
            TableCaption.displayName = "TableCaption"

            export {
              Table,
              TableHeader,
              TableBody,
              TableFooter,
              TableHead,
              TableRow,
              TableCell,
              TableCaption,
            }


            
            `,
          },
/// tabs listo xd
          '/components/ui/tabs.tsx': {
            code: `
            import * as React from "react"
            import * as TabsPrimitive from "@radix-ui/react-tabs"

            import { cn } from "../../lib/utils"

            const Tabs = TabsPrimitive.Root

            const TabsList = React.forwardRef<
              React.ElementRef<typeof TabsPrimitive.List>,
              React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
            >(({ className, ...props }, ref) => (
              <TabsPrimitive.List
                ref={ref}
                className={cn(
                  "inline-flex h-10 items-center justify-center rounded-md bg-gray-100 p-1 text-gray-500 dark:bg-gray-800 dark:text-gray-400",
                  className
                )}
                {...props}
              />
            ))
            TabsList.displayName = TabsPrimitive.List.displayName

            const TabsTrigger = React.forwardRef<
              React.ElementRef<typeof TabsPrimitive.Trigger>,
              React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
            >(({ className, ...props }, ref) => (
              <TabsPrimitive.Trigger
                ref={ref}
                className={cn(
                  "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-gray-950 data-[state=active]:shadow-sm dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300 dark:data-[state=active]:bg-gray-950 dark:data-[state=active]:text-gray-50",
                  className
                )}
                {...props}
              />
            ))
            TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

            const TabsContent = React.forwardRef<
              React.ElementRef<typeof TabsPrimitive.Content>,
              React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
            >(({ className, ...props }, ref) => (
              <TabsPrimitive.Content
                ref={ref}
                className={cn(
                  "mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
                  className
                )}
                {...props}
              />
            ))
            TabsContent.displayName = TabsPrimitive.Content.displayName

            export { Tabs, TabsList, TabsTrigger, TabsContent }


            `,
          },
///textarea listo xd
          '/components/ui/textarea.tsx': {
            code: `
            import * as React from "react"

            import { cn } from "../../lib/utils"

            export interface TextareaProps
              extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

            const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
              ({ className, ...props }, ref) => {
                return (
                  <textarea
                    className={cn(
                      "flex min-h-[80px] w-full rounded-md border border-gray-200 bg-white px-3 py-2 text-sm ring-offset-white placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:bg-gray-950 dark:ring-offset-gray-950 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300",
                      className
                    )}
                    ref={ref}
                    {...props}
                  />
                )
              }
            )
            Textarea.displayName = "Textarea"

            export { Textarea }
            `,
          },
///toast listo xd
          '/components/ui/toast.tsx': {
            code: `
            import * as React from "react"
            import * as ToastPrimitives from "@radix-ui/react-toast"
            import { cva, type VariantProps } from "class-variance-authority"
            import { X } from "lucide-react"

            import { cn } from "../../lib/utils"

            const ToastProvider = ToastPrimitives.Provider

            const ToastViewport = React.forwardRef<
              React.ElementRef<typeof ToastPrimitives.Viewport>,
              React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
            >(({ className, ...props }, ref) => (
              <ToastPrimitives.Viewport
                ref={ref}
                className={cn(
                  "fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
                  className
                )}
                {...props}
              />
            ))
            ToastViewport.displayName = ToastPrimitives.Viewport.displayName

            const toastVariants = cva(
              "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border border-gray-200 p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full dark:border-gray-800",
              {
                variants: {
                  variant: {
                    default: "border bg-white text-gray-950 dark:bg-gray-950 dark:text-gray-50",
                    destructive:
                      "destructive group border-red-500 bg-red-500 text-gray-50 dark:border-red-900 dark:bg-red-900 dark:text-gray-50",
                  },
                },
                defaultVariants: {
                  variant: "default",
                },
              }
            )

            const Toast = React.forwardRef<
              React.ElementRef<typeof ToastPrimitives.Root>,
              React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
                VariantProps<typeof toastVariants>
            >(({ className, variant, ...props }, ref) => {
              return (
                <ToastPrimitives.Root
                  ref={ref}
                  className={cn(toastVariants({ variant }), className)}
                  {...props}
                />
              )
            })
            Toast.displayName = ToastPrimitives.Root.displayName

            const ToastAction = React.forwardRef<
              React.ElementRef<typeof ToastPrimitives.Action>,
              React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
            >(({ className, ...props }, ref) => (
              <ToastPrimitives.Action
                ref={ref}
                className={cn(
                  "inline-flex h-8 shrink-0 items-center justify-center rounded-md border border-gray-200 bg-transparent px-3 text-sm font-medium ring-offset-white transition-colors hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-950 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-gray-100/40 group-[.destructive]:hover:border-red-500/30 group-[.destructive]:hover:bg-red-500 group-[.destructive]:hover:text-gray-50 group-[.destructive]:focus:ring-red-500 dark:border-gray-800 dark:ring-offset-gray-950 dark:hover:bg-gray-800 dark:focus:ring-gray-300 dark:group-[.destructive]:border-gray-800/40 dark:group-[.destructive]:hover:border-red-900/30 dark:group-[.destructive]:hover:bg-red-900 dark:group-[.destructive]:hover:text-gray-50 dark:group-[.destructive]:focus:ring-red-900",
                  className
                )}
                {...props}
              />
            ))
            ToastAction.displayName = ToastPrimitives.Action.displayName

            const ToastClose = React.forwardRef<
              React.ElementRef<typeof ToastPrimitives.Close>,
              React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
            >(({ className, ...props }, ref) => (
              <ToastPrimitives.Close
                ref={ref}
                className={cn(
                  "absolute right-2 top-2 rounded-md p-1 text-gray-950/50 opacity-0 transition-opacity hover:text-gray-950 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600 dark:text-gray-50/50 dark:hover:text-gray-50",
                  className
                )}
                toast-close=""
                {...props}
              >
                <X className="h-4 w-4" />
              </ToastPrimitives.Close>
            ))
            ToastClose.displayName = ToastPrimitives.Close.displayName

            const ToastTitle = React.forwardRef<
              React.ElementRef<typeof ToastPrimitives.Title>,
              React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
            >(({ className, ...props }, ref) => (
              <ToastPrimitives.Title
                ref={ref}
                className={cn("text-sm font-semibold", className)}
                {...props}
              />
            ))
            ToastTitle.displayName = ToastPrimitives.Title.displayName

            const ToastDescription = React.forwardRef<
              React.ElementRef<typeof ToastPrimitives.Description>,
              React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
            >(({ className, ...props }, ref) => (
              <ToastPrimitives.Description
                ref={ref}
                className={cn("text-sm opacity-90", className)}
                {...props}
              />
            ))
            ToastDescription.displayName = ToastPrimitives.Description.displayName

            type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

            type ToastActionElement = React.ReactElement<typeof ToastAction>

            export {
              type ToastProps,
              type ToastActionElement,
              ToastProvider,
              ToastViewport,
              Toast,
              ToastTitle,
              ToastDescription,
              ToastClose,
              ToastAction,
            }
            
            `,
          },
/// toaster listo xd
          '/components/ui/toaster.tsx': {
            code: `
            import {
              Toast,
              ToastClose,
              ToastDescription,
              ToastProvider,
              ToastTitle,
              ToastViewport,
            } from "./toast"
            import { useToast } from "./use-toast"

            export function Toaster() {
              const { toasts } = useToast()

              return (
                <ToastProvider>
                  {toasts.map(function ({ id, title, description, action, ...props }) {
                    return (
                      <Toast key={id} {...props}>
                        <div className="grid gap-1">
                          {title && <ToastTitle>{title}</ToastTitle>}
                          {description && (
                            <ToastDescription>{description}</ToastDescription>
                          )}
                        </div>
                        {action}
                        <ToastClose />
                      </Toast>
                    )
                  })}
                  <ToastViewport />
                </ToastProvider>
              )
            }

                        
            `,
          },
///toogle-groups lisot xd
          '/components/ui/toggle-group.tsx': {
            code: `
            import * as React from "react"
            import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group"
            import { type VariantProps } from "class-variance-authority"

            import { cn } from "../../lib/utils"
            import { toggleVariants } from "./toggle"

            const ToggleGroupContext = React.createContext<
              VariantProps<typeof toggleVariants>
            >({
              size: "default",
              variant: "default",
            })

            const ToggleGroup = React.forwardRef<
              React.ElementRef<typeof ToggleGroupPrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
                VariantProps<typeof toggleVariants>
            >(({ className, variant, size, children, ...props }, ref) => (
              <ToggleGroupPrimitive.Root
                ref={ref}
                className={cn("flex items-center justify-center gap-1", className)}
                {...props}
              >
                <ToggleGroupContext.Provider value={{ variant, size }}>
                  {children}
                </ToggleGroupContext.Provider>
              </ToggleGroupPrimitive.Root>
            ))

            ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

            const ToggleGroupItem = React.forwardRef<
              React.ElementRef<typeof ToggleGroupPrimitive.Item>,
              React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
                VariantProps<typeof toggleVariants>
            >(({ className, children, variant, size, ...props }, ref) => {
              const context = React.useContext(ToggleGroupContext)

              return (
                <ToggleGroupPrimitive.Item
                  ref={ref}
                  className={cn(
                    toggleVariants({
                      variant: context.variant || variant,
                      size: context.size || size,
                    }),
                    className
                  )}
                  {...props}
                >
                  {children}
                </ToggleGroupPrimitive.Item>
              )
            })

            ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName

            export { ToggleGroup, ToggleGroupItem }


            `,
          },
/// toggle listo xd
          '/components/ui/toggle.tsx': {
            code: `
            import * as React from "react"
            import * as TogglePrimitive from "@radix-ui/react-toggle"
            import { cva, type VariantProps } from "class-variance-authority"

            import { cn } from "../../lib/utils"

            const toggleVariants = cva(
              "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors hover:bg-gray-100 hover:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-gray-100 data-[state=on]:text-gray-900 dark:ring-offset-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-400 dark:focus-visible:ring-gray-300 dark:data-[state=on]:bg-gray-800 dark:data-[state=on]:text-gray-50",
              {
                variants: {
                  variant: {
                    default: "bg-transparent",
                    outline:
                      "border border-gray-200 bg-transparent hover:bg-gray-100 hover:text-gray-900 dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-50",
                  },
                  size: {
                    default: "h-10 px-3",
                    sm: "h-9 px-2.5",
                    lg: "h-11 px-5",
                  },
                },
                defaultVariants: {
                  variant: "default",
                  size: "default",
                },
              }
            )

            const Toggle = React.forwardRef<
              React.ElementRef<typeof TogglePrimitive.Root>,
              React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
                VariantProps<typeof toggleVariants>
            >(({ className, variant, size, ...props }, ref) => (
              <TogglePrimitive.Root
                ref={ref}
                className={cn(toggleVariants({ variant, size, className }))}
                {...props}
              />
            ))

            Toggle.displayName = TogglePrimitive.Root.displayName

            export { Toggle, toggleVariants }


            `,
          },
///tooltop listo xd
          '/components/ui/tooltip.tsx': {
            code: `
            import * as React from "react"
            import * as TooltipPrimitive from "@radix-ui/react-tooltip"

            import { cn } from "../../lib/utils"

            const TooltipProvider = TooltipPrimitive.Provider

            const Tooltip = TooltipPrimitive.Root

            const TooltipTrigger = TooltipPrimitive.Trigger

            const TooltipContent = React.forwardRef<
              React.ElementRef<typeof TooltipPrimitive.Content>,
              React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
            >(({ className, sideOffset = 4, ...props }, ref) => (
              <TooltipPrimitive.Content
                ref={ref}
                sideOffset={sideOffset}
                className={cn(
                  "z-50 overflow-hidden rounded-md border border-gray-200 bg-white px-3 py-1.5 text-sm text-gray-950 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-800 dark:bg-gray-950 dark:text-gray-50",
                  className
                )}
                {...props}
              />
            ))
            TooltipContent.displayName = TooltipPrimitive.Content.displayName

            export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }


            `,
          },
/// ya etsa use-toast
            '/components/ui/use-toast.tsx': {
              code: `
              import * as React from "react"

              import type {
                ToastActionElement,
                ToastProps,
              } from "./toast"

              const TOAST_LIMIT = 1
              const TOAST_REMOVE_DELAY = 1000000

              type ToasterToast = ToastProps & {
                id: string
                title?: React.ReactNode
                description?: React.ReactNode
                action?: ToastActionElement
              }

              const actionTypes = {
                ADD_TOAST: "ADD_TOAST",
                UPDATE_TOAST: "UPDATE_TOAST",
                DISMISS_TOAST: "DISMISS_TOAST",
                REMOVE_TOAST: "REMOVE_TOAST",
              } as const

              let count = 0

              function genId() {
                count = (count + 1) % Number.MAX_SAFE_INTEGER
                return count.toString()
              }

              type ActionType = typeof actionTypes

              type Action =
                | {
                    type: ActionType["ADD_TOAST"]
                    toast: ToasterToast
                  }
                | {
                    type: ActionType["UPDATE_TOAST"]
                    toast: Partial<ToasterToast>
                  }
                | {
                    type: ActionType["DISMISS_TOAST"]
                    toastId?: ToasterToast["id"]
                  }
                | {
                    type: ActionType["REMOVE_TOAST"]
                    toastId?: ToasterToast["id"]
                  }

              interface State {
                toasts: ToasterToast[]
              }

              const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>()

              const addToRemoveQueue = (toastId: string) => {
                if (toastTimeouts.has(toastId)) {
                  return
                }

                const timeout = setTimeout(() => {
                  toastTimeouts.delete(toastId)
                  dispatch({
                    type: "REMOVE_TOAST",
                    toastId: toastId,
                  })
                }, TOAST_REMOVE_DELAY)

                toastTimeouts.set(toastId, timeout)
              }

              export const reducer = (state: State, action: Action): State => {
                switch (action.type) {
                  case "ADD_TOAST":
                    return {
                      ...state,
                      toasts: [action.toast, ...state.toasts].slice(0, TOAST_LIMIT),
                    }

                  case "UPDATE_TOAST":
                    return {
                      ...state,
                      toasts: state.toasts.map((t) =>
                        t.id === action.toast.id ? { ...t, ...action.toast } : t
                      ),
                    }

                  case "DISMISS_TOAST": {
                    const { toastId } = action

                    // ! Side effects ! - This could be extracted into a dismissToast() action,
                    // but I'll keep it here for simplicity
                    if (toastId) {
                      addToRemoveQueue(toastId)
                    } else {
                      state.toasts.forEach((toast) => {
                        addToRemoveQueue(toast.id)
                      })
                    }

                    return {
                      ...state,
                      toasts: state.toasts.map((t) =>
                        t.id === toastId || toastId === undefined
                          ? {
                              ...t,
                              open: false,
                            }
                          : t
                      ),
                    }
                  }
                  case "REMOVE_TOAST":
                    if (action.toastId === undefined) {
                      return {
                        ...state,
                        toasts: [],
                      }
                    }
                    return {
                      ...state,
                      toasts: state.toasts.filter((t) => t.id !== action.toastId),
                    }
                }
              }

              const listeners: Array<(state: State) => void> = []

              let memoryState: State = { toasts: [] }

              function dispatch(action: Action) {
                memoryState = reducer(memoryState, action)
                listeners.forEach((listener) => {
                  listener(memoryState)
                })
              }

              type Toast = Omit<ToasterToast, "id">

              function toast({ ...props }: Toast) {
                const id = genId()

                const update = (props: ToasterToast) =>
                  dispatch({
                    type: "UPDATE_TOAST",
                    toast: { ...props, id },
                  })
                const dismiss = () => dispatch({ type: "DISMISS_TOAST", toastId: id })

                dispatch({
                  type: "ADD_TOAST",
                  toast: {
                    ...props,
                    id,
                    open: true,
                    onOpenChange: (open) => {
                      if (!open) dismiss()
                    },
                  },
                })

                return {
                  id: id,
                  dismiss,
                  update,
                }
              }

              function useToast() {
                const [state, setState] = React.useState<State>(memoryState)

                React.useEffect(() => {
                  listeners.push(setState)
                  return () => {
                    const index = listeners.indexOf(setState)
                    if (index > -1) {
                      listeners.splice(index, 1)
                    }
                  }
                }, [state])

                return {
                  ...state,
                  toast,
                  dismiss: (toastId?: string) => dispatch({ type: "DISMISS_TOAST", toastId }),
                }
              }

              export { useToast, toast }

              `,
            },

              '/lib/utils.js': {
              code: `
                import { type ClassValue, clsx } from "clsx"
                import { twMerge } from "tailwind-merge"

                export function cn(...inputs: ClassValue[]) {
                  return twMerge(clsx(inputs))
                }

              `,
            },
            
            '/public/index.html': {
              code: `
              <!DOCTYPE html>
                <html lang="en">
                  <head>
                    <meta charset="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <title>Creado por Xtarify</title>
                    <link
                      href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css"
                      rel="stylesheet"
                    />
                  </head>
                  <body>
                    <div id="root"></div>
                  </body>
                </html>
              `,
            },
          };
          

        // Actualizar los archivos de Sandpack
          // Actualizar el código que se muestra en Sandpack con la respuesta de la IA
          setSandpackFiles({
            'App.js': {
              code: responseText,
            },
            
            ...shadcnComponents,
            'index.js': {
              code: `
                import React from 'react';
                import ReactDOM from 'react-dom';
                import App from './App';
  
                ReactDOM.render(
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>,
                  document.getElementById('root')
                );
              `,
            },
            'index.css': {
              code: `
                @tailwind base;
                @tailwind components;
                @tailwind utilities;
              `,
            },
            'tailwind.config.js': {
              code: `
                module.exports = {
                  content: ['./**/*.{js,jsx,ts,tsx}'],
                  theme: {
                    extend: {},
                  },
                  plugins: [],
                };
              `,
            },
            'postcss.config.js': {
              code: `
                module.exports = {
                  plugins: {
                    tailwindcss: {},
                    autoprefixer: {},
                  },
                };
              `,
            },
            'package.json': {
              code: JSON.stringify(
                {
                  "dependencies": {
                    "react": "^17.0.0",
                    "react-dom": "^17.0.0",
                    "react-scripts": "^4.0.0",
                    "lucide-react": "latest",
                    "recharts": "2.9.0",
                    "react-router-dom": "latest",
                    "@radix-ui/react-accordion": "^1.2.0",
                    "@radix-ui/react-alert-dialog": "^1.1.1",
                    "@radix-ui/react-aspect-ratio": "^1.1.0",
                    "@radix-ui/react-avatar": "^1.1.0",
                    "@radix-ui/react-checkbox": "^1.1.1",
                    "@radix-ui/react-collapsible": "^1.1.0",
                    "@radix-ui/react-dialog": "^1.1.1",
                    "@radix-ui/react-dropdown-menu": "^2.1.1",
                    "@radix-ui/react-hover-card": "^1.1.1",
                    "@radix-ui/react-label": "^2.1.0",
                    "@radix-ui/react-menubar": "^1.1.1",
                    "@radix-ui/react-navigation-menu": "^1.2.0",
                    "@radix-ui/react-popover": "^1.1.1",
                    "@radix-ui/react-progress": "^1.1.0",
                    "@radix-ui/react-radio-group": "^1.2.0",
                    "@radix-ui/react-select": "^2.1.1",
                    "@radix-ui/react-separator": "^1.1.0",
                    "@radix-ui/react-slider": "^1.2.0",
                    "@radix-ui/react-slot": "^1.1.0",
                    "@radix-ui/react-switch": "^1.1.0",
                    "@radix-ui/react-tabs": "^1.1.0",
                    "@radix-ui/react-toast": "^1.2.1",
                    "@radix-ui/react-toggle": "^1.1.0",
                    "@radix-ui/react-toggle-group": "^1.1.0",
                    "@radix-ui/react-tooltip": "^1.1.2",
                    "class-variance-authority": "^0.7.0",
                    "clsx": "^2.1.1",
                    "date-fns": "^3.6.0",
                    "embla-carousel-react": "^8.1.8",
                    "react-day-picker": "^8.10.1",
                    "tailwind-merge": "^2.4.0",
                    "tailwindcss-animate": "^1.0.7",
                    
                    "vaul": "^0.9.1"
                  },
                  "devDependencies": {
                    "@types/react": "^17.0.0",
                    "@types/react-dom": "^17.0.0",
                    "typescript": "^4.0.0",
                    "tailwindcss": "^3.3.0",
                    "postcss": "^8.4.0",
                    "autoprefixer": "^10.4.0"
                  },
                  "main": "App.js"
                },
                
                null,
                2
              ),
            },
          });
          ///////////////////////////////////////////



          setPromptSent(true);
          setLoading(false); // Detener la animación cuando el servidor responde
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Hubo un error al enviar los datos');
          setLoading(false); // Detener la animación si hay un error
        });
    }, 1000); // Simula un retraso de 5 segundos en la respuesta
  };

  // Enviar el POST al presionar Enter
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendData();
    }
  };

  // Funciones para manejar cambios en el input
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  /////////////////////////////////// CODIGO DE MODIFICACIONES
  const sendModification = () => {
    if (!modificationInput) {
      alert('Por favor ingresa tus modificaciones.');
      return;
    }

    setLoading(true);

    // Enviar solicitud POST al backend
    fetch('https://api.xtarify.com/api/improve-code/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        modifications: modificationInput,
        code: sandpackFiles['/index.html'].code,
      }),
      mode: 'cors',
    })
      .then((response) => response.json())
      .then((result) => {
        let responseText = result.response || '// No se pudo obtener la respuesta de la IA';
        // Actualizar el código que se muestra en Sandpack con el código mejorado
        setSandpackFiles({
          'index.html': {
            code: responseText,
            hidden: false,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Hubo un error al enviar las modificaciones');
        setLoading(false);
      });
  };
  /////////////////////////////////// CODIGO DE MODIFICACIONES

  // Función para manejar el prompt generado desde PromptGenerator
  const handlePromptGenerated = (generatedPrompt) => {
    setInputData(generatedPrompt);
    sendData();
  };

  return (
    <div>
      {/* Header */}
      {!promptSent && (
        <header className="header">
          <a href="/" className="home-link">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo.png`}
              alt="Xtarify Logo"
              className="logo"
            />
            <span className="site-title">Xtarify</span>
          </a>
        </header>
      )}

      {/* Botón de regreso al home */}
      {promptSent && (
        <button className="back-button" onClick={() => setPromptSent(false)}>
          <img
            width="60"
            height="60"
            src={`${process.env.PUBLIC_URL}/assets/flecha_back.png`}
            alt="Regresar"
          />
        </button>
      )}

      {/* Background Video */}
      <div className="video-background">
        <video autoPlay loop muted>
          <source
            src={`${process.env.PUBLIC_URL}/assets/background.mp4`}
            type="video/mp4"
          />
        </video>
      </div>

      {/* Beta Version Info */}
      {!promptSent && (
        <>
          <div className="beta-version" id="betaButton" onClick={toggleVersionInfo}>
            Beta v0.8
          </div>
          {versionVisible && (
            <div className="version-info show" id="versionInfo">
              <h2 className="updates-title">Updates</h2>
              <div className="updates">
                <div className="update-box">
                  <strong>ㅤv0.7ㅤ</strong>
                  <p>Better orchestrator</p>
                </div>
                <div className="update-box">
                  <strong>ㅤv0.6ㅤ</strong>
                  <p>Feedback bottom</p>
                </div>
                <div className="update-box">
                  <strong>ㅤv0.5ㅤ</strong>
                  <p>Preview working</p>
                </div>
                <div className="update-box">
                  <strong>ㅤv0.4ㅤ</strong>
                  <p>Improvements</p>
                </div>
                <div className="update-box">
                  <strong>ㅤv0.3ㅤ</strong>
                  <p>Orchestrator Implemented</p>
                </div>
                <div className="update-box">
                  <strong>ㅤv0.2ㅤ</strong>
                  <p>Backend working</p>
                </div>
                <div className="update-box">
                  <strong>ㅤv0.1ㅤ</strong>
                  <p>Initial Design</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* Feedback Modal */}
      <button id="feedbackButton" className="feedback-btn" onClick={openFeedback}>
        Feedback
      </button>
      {feedbackVisible && (
        <div
          className="feedback-modal show"
          id="feedbackModal"
          onClick={handleFeedbackClickOutside}
        >
          <div className="modal-content">
            <span className="close-btn" onClick={closeFeedback}>
              &times;
            </span>
            <h2 className="modal-title">Déjanos tu feedback</h2>
            <textarea
              className="feedback-textarea"
              placeholder="Escribe aquí tu feedback..."
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            ></textarea>
            <button className="submit-feedback-btn" onClick={handleFeedbackSubmit}>
              Enviar
            </button>
          </div>
        </div>
      )}

      {/* Loading Animation */}
      {loading ? (
        <div style={styles.waveTextContainer}>
          <h1 style={{ color: '#fff' }}>Generating your next big project</h1>
          <div
            id="changing-text"
            className="wave-text animating"
            data-text={currentText}
            style={{ color: '#fff' }}
          >
            {currentText}
          </div>
        </div>
      ) : (
        <>
          {/* Renderizar componente basado en la tecnología AI seleccionada */}
        {/* Contenido Principal */}
      {!promptSent && (
        <>
          {/* Selección de modelo de IA */}
          <div className="content">
            <h1>
              What can I help you <span className="highlight-blue">build</span> today?
            </h1>
            <h3 className="small-text">
              Generate websites, ask questions, remember better prompt, better results.
            </h3>

            <div className="model-selection">
              <label htmlFor="model-select">AI Technology:</label>
              <select
                id="model-select"
                value={model}
                onChange={handleModelSelect}
              >
                <option value="">Selecciona una opción</option>
                <option value="shadcn-typescript">Shadcn/UI + Typescript</option>
                <option value="javascript-sandboxer">Javascript Sandboxer</option>
                <option value="cpp-python-expert">C++ and Python Expert Developer</option>
              </select>
            </div>
          </div>
        </>
      )}

      {/* Renderizar el componente correspondiente basado en el modelo seleccionado */}
      {model === 'shadcn-typescript' && (
        <ShadcnTypescript
          setPromptSent={setPromptSent}
          promptSent={promptSent}
        />
      )}
      {model === 'javascript-sandboxer' && (
        <JavascriptSandboxer
          setPromptSent={setPromptSent}
          promptSent={promptSent}
        />
      )}
      {model === 'cpp-python-expert' && (
        <CppPythonExpert
          setPromptSent={setPromptSent}
          promptSent={promptSent}
        />
      )}

          {/* Sandpack Preview (Visible solo después de enviar prompt) */}
          {promptSent && <SandpackComponent sandpackFiles={sandpackFiles} />}

          {/* Modification Input con el nuevo diseño */}
          {promptSent && (
            <div className="input-group">
              <div className="input-wrapper">
                <textarea
                  className="auto-resize"
                  placeholder="Describe las modificaciones que deseas..."
                  value={modificationInput}
                  onChange={(e) => setModificationInput(e.target.value)}
                />
                <button className="try-button" onClick={sendModification}>
                  <svg
                    className="send-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3.4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {/* Footer */}
      {!promptSent && (
        <footer className="footer">
          <a href="/faq" className="footer-button">
            FAQ
          </a>
          <a href="/ai-policy" className="footer-button">
            Política de AI
          </a>
          <a href="/nosotros" className="footer-button">
            Nosotros
          </a>
        </footer>
      )}

      {/* Redes Sociales */}
      {!promptSent && (
        <div className="social-buttons">
          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/company/xtarify/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button linkedin-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="36"
              height="36"
              fill="currentColor"
            >
              <path
                fill="currentColor"
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              ></path>
            </svg>
          </a>

          {/* Discord */}
          <a
            href="https://discord.com"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button discord-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              width="36"
              height="36"
              fill="currentColor"
            >
              <path
                fill="currentColor"
                d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"
              ></path>
            </svg>
          </a>

          {/* Facebook */}
          <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button facebook-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              width="36"
              height="36"
              fill="currentColor"
            >
              <path
                fill="currentColor"
                d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
              ></path>
            </svg>
          </a>
        </div>
      )}
    </div>
  );
}

const styles = {
  waveTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'transparent', // Fondo transparente para usar el fondo del video
    color: '#fff', // Cambiar color del texto a blanco
  },
};

export default App;
